import { DateTime } from 'luxon';
import { atom, useRecoilState } from 'recoil';

const getLocalTimeZone = () => DateTime.local().zoneName;

export type TimeZonePreferenceHook = [TimeZonePreference, (preference: TimeZonePreference) => void];

export type TimeZonePreference = TimeZone | typeof UseBrowserTimeZone;

export const UseBrowserTimeZone = 'use-browser-time-zone';

export type TimeZone = string;

export interface DateTimeHook {
  timeZone: TimeZone;
  isLocalTimeZone: boolean;
  now(): DateTime;
  formatDateTime(date: DateTime | string): string;
  formatShortDateTime(date: DateTime | string): string;
  formatShortDate(date: DateTime | string): string;
  toDateTime(date: DateTime | string): DateTime;
  fromSeconds(seconds: number | string): DateTime;
  fromMillis(millis: number | string): DateTime;
}

export const useDateTime = (): DateTimeHook => {
  const [timeZonePreference] = useTimeZonePreference();
  return buildDateTimeHook(timeZonePreference);
};

const UTC_TIMEZONE = 'UTC';

const buildDateTimeHook = (timeZonePreference: TimeZonePreference) => {
  const timeZone = getTimeZone(timeZonePreference);
  const isLocalTimeZone = timeZone === getLocalTimeZone();

  const now = () => DateTime.local().setZone(timeZone);

  const formatDateTime = (date: DateTime | string) => {
    const dt = toDateTime(date);

    if (timeZone === UTC_TIMEZONE) {
      return dt.toFormat('yyyy-MM-dd HH:mm:ss ZZZZ');
    }

    return dt.toLocaleString(DateTime.DATETIME_FULL);
  };

  const formatShortDateTime = (date: DateTime | string) => {
    const dt = toDateTime(date);

    if (timeZone === UTC_TIMEZONE) {
      return dt.toFormat('yyyy-MM-dd HH:mm');
    }

    return dt.toLocaleString(DateTime.DATETIME_SHORT);
  };

  const formatShortDate = (date: DateTime | string) => {
    const dt = toDateTime(date);

    if (timeZone === UTC_TIMEZONE) {
      return dt.toFormat('yyyy-MM-dd');
    }

    return dt.toLocaleString(DateTime.DATE_SHORT);
  };

  const fromSeconds = (seconds: number | string) => DateTime.fromSeconds(seconds, { zone: timeZone });

  const fromMillis = (millis: number | string) => DateTime.fromMillis(millis, { zone: timeZone });

  const toDateTime = (date: DateTime | string): DateTime => {
    if (typeof date === 'string') {
      return DateTime.fromISO(date, { zone: timeZone });
    }
    return date;
  };

  return {
    timeZone,
    isLocalTimeZone,
    now,
    formatDateTime,
    formatShortDateTime,
    formatShortDate,
    fromSeconds,
    fromMillis,
    toDateTime,
  };
};

export const useTimeZone = (): TimeZone => {
  const [timeZonePreference] = useTimeZonePreference();
  return getTimeZone(timeZonePreference);
};

export const useTimeZonePreference = (): TimeZonePreferenceHook => {
  const [datePreferences, setDatePreferences] = useRecoilState(datePreferencesState);

  return [
    datePreferences.timeZone,
    (preference: TimeZonePreference) => {
      setDatePreferences((current) => ({ ...current, timeZone: preference }));
    },
  ];
};

interface DatePreferencesState {
  timeZone: TimeZonePreference;
}

const timeZonePreferenceStorageKey = 'time-zone-preference';

const getTimeZone = (timeZonePreference) => {
  if (timeZonePreference === UseBrowserTimeZone) {
    return getLocalTimeZone();
  }

  return timeZonePreference;
};

const getUserTimeZonePreference = (): TimeZonePreference => {
  const currentValue = window.localStorage.getItem(timeZonePreferenceStorageKey);

  if (currentValue) {
    return currentValue;
  }

  return UseBrowserTimeZone;
};

const setUserTimeZonePreference = (timeZone: TimeZonePreference) => {
  window.localStorage.setItem(timeZonePreferenceStorageKey, timeZone);
};

const datePreferencesState = atom<DatePreferencesState>({
  key: 'datePreferences',
  default: { timeZone: getUserTimeZonePreference() },
  effects: [({ onSet }) => onSet((newValue) => setUserTimeZonePreference(newValue.timeZone))],
});
