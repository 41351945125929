import { gql } from '@apollo/client';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import * as React from 'react';
import { useDateTime } from '../../data/dates';
import { TransferNoticesTableRow_TransferNotice } from '../../generated/graphql';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';
import UsdFormat from '../common/typography/UsdFormat';

interface TransferNoticesTableProps {
  transferNotices?: readonly TransferNoticesTableRow_TransferNotice[];
  onClickTransferNoticeRow?: TransferNoticeClickEventHandler;
}

export type TransferNoticeClickEventHandler = (transferNoticeId: string) => void;

interface TransferNoticesTableRowData extends TransferNoticesTableRow_TransferNotice {
  fundName: string;
}

const TransferNoticesTable = ({ transferNotices, onClickTransferNoticeRow }: TransferNoticesTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<TransferNoticesTableRowData>('createdAt', 'desc');

  const showFund = (new Set(transferNotices?.map(({ fund }) => fund.id)) ?? new Set()).size > 1;

  const transferNoticeData = transferNotices?.map((transferNotice) => ({
    ...transferNotice,
    fundName: transferNotice.fund.name,
  }));

  const createClickHandler = (transferNoticeId: string) => {
    if (!onClickTransferNoticeRow) {
      return undefined;
    }
    return () => onClickTransferNoticeRow(transferNoticeId);
  };

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <TransferNoticesTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          showFund={showFund}
        />
        <TableBody>
          {transferNoticeData && transferNoticeData.length > 0 ? (
            stableSort(transferNoticeData, getComparator(order, orderBy)).map((transferNotice) => (
              <TransferNoticeTableRow
                key={transferNotice.id}
                transferNotice={transferNotice}
                showFund={showFund}
                onRowClick={createClickHandler(transferNotice.id)}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>
              {transferNotices ? 'No wire transfers found or processed.' : 'Loading transfer notices...'}
            </EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default TransferNoticesTable;

interface TransferNoticesTableHeadProps
  extends Omit<SimpleSortingTableHeadProps<TransferNoticesTableRowData>, 'headings'> {
  showFund: boolean;
}

const columnHeadings: SimpleSortingTableHeadCell<TransferNoticesTableRowData>[] = [
  { key: 'fundName', label: 'Fund', align: 'left' },
  { key: 'originatingAccountNumber', label: 'Originating Account Number', align: 'left' },
  { key: 'amount', label: 'Transfer Amount', align: 'right' },
  { key: 'createdAt', label: 'Submitted', align: 'right' },
  { key: 'processedAt', label: 'Processed', align: 'right' },
  { key: 'effectiveAt', label: 'Effective', align: 'right' },
];

const columnHeadingsWithoutFund = columnHeadings.filter(({ key }) => key !== 'fundName');

const TransferNoticesTableHead = ({ showFund, ...props }: TransferNoticesTableHeadProps) => (
  <SimpleSortingTableHead {...props} headings={showFund ? columnHeadings : columnHeadingsWithoutFund} />
);

type TransferNoticeTableRowProps = {
  transferNotice: TransferNoticesTableRowData;
  showFund: boolean;
  onRowClick?: React.MouseEventHandler;
};

export const TransferNoticeTableRow = ({ transferNotice, showFund, onRowClick }: TransferNoticeTableRowProps) => {
  const { formatShortDateTime } = useDateTime();

  return (
    <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
      {showFund && <TableCell title={transferNotice.fund.legalName}>{transferNotice.fundName}</TableCell>}
      <TableCell>{transferNotice.originatingAccountNumber}</TableCell>
      <TableCell align="right">
        <UsdFormat amount={transferNotice.amount} />
      </TableCell>
      <TableCell align="right">{formatShortDateTime(transferNotice.createdAt)}</TableCell>
      <TableCell align="right">
        {transferNotice.processedAt ? formatShortDateTime(transferNotice.processedAt) : null}
      </TableCell>
      <TableCell align="right">
        {transferNotice.effectiveAt ? formatShortDateTime(transferNotice.effectiveAt) : null}
      </TableCell>
    </AlternatingTableRow>
  );
};

export const transferNoticesTableRowFragment = gql`
  fragment TransferNoticesTableRow_TransferNotice on TransferNotice {
    id
    fund {
      id
      name
      legalName
    }
    originatingAccountNumber
    amount
    processedAt
    effectiveAt
    createdAt
  }
`;
