import TopNavItem from '@paypr/mui5-common-components/dist/components/page/nav/TopNavItem';
import TopNavTabs from '@paypr/mui5-common-components/dist/components/page/nav/TopNavTabs';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { documentsPath, homePath, performancePath, supportPath, transferPath } from '../../routes/paths';

export interface AppTopNavProps {
  disabled?: boolean;
}

const AppTopNav = ({}: AppTopNavProps) => {
  const location = useLocation();

  return (
    <TopNavTabs value={location.pathname}>
      <TopNavItem title="Home" value={homePath} />
      <TopNavItem title="Performance" value={performancePath} />
      <TopNavItem title="Transfer" value={transferPath} />
      <TopNavItem title="Documents" value={documentsPath} />
      <TopNavItem title="Support" value={supportPath} />
    </TopNavTabs>
  );
};
export default AppTopNav;
