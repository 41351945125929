import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useState } from 'react';
import UserIcon from '../../components/common/icons/UserIcon';
import PageFooter from '../../components/common/page/PageFooter';
import { useAuthentication } from '../../hooks/auth';
import { ErrorKey, useErrors } from '../../hooks/errors';
import { usePageTitle } from '../../hooks/page';
import FullPageContainer from '@paypr/mui5-common-components/dist/components/page/FullPageContainer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import LoginForm from './LoginForm';

const LoginPage = () => {
  const [loginErrorKey, setLoginErrorKey] = useState<ErrorKey>();
  const { login, loading } = useAuthentication();
  const { showError, clearError } = useErrors();

  usePageTitle('Login');

  const handleLogin = async (email: string, password: string) => {
    try {
      if (loginErrorKey) {
        clearError(loginErrorKey);
        setLoginErrorKey(undefined);
      }

      await login(email, password);
    } catch (e) {
      setLoginErrorKey(showError(e));
    }
  };

  return (
    <FullPageContainer>
      <Paper>
        <Toolbar>
          <UserIcon />
          &nbsp;
          <SectionTitle>Login</SectionTitle>
        </Toolbar>

        <LoginForm onLogin={handleLogin} loading={loading} />

        <PageFooter />
      </Paper>
    </FullPageContainer>
  );
};
export default LoginPage;
