import * as React from 'react';
import AppPage from '../components/common/page/AppPage';
import ContactUsIntegration from '../components/contactUs/ContactUsIntegration';
import { usePageTitle } from '../hooks/page';

const SupportPage = () => {
  usePageTitle('Support');

  return (
    <AppPage>
      <ContactUsIntegration />
    </AppPage>
  );
};
export default SupportPage;
