import PageLoader from '@paypr/mui5-common-components/dist/components/page/PageLoader';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthentication } from '../../../hooks/auth';
import { homePath, RedirectQueryParams, useQuery } from './paths';

export interface UnauthenticatedProps {
  children?: React.ReactNode;
}

const Unauthenticated = ({ children }: UnauthenticatedProps) => {
  const { isAuthenticated, loading } = useAuthentication();
  const { params } = useQuery<RedirectQueryParams>();

  const redirect = params.get('redirect');

  if (loading) {
    return <PageLoader />;
  }

  if (!isAuthenticated) {
    return <>{children}</>;
  }

  return <Navigate to={redirect === '' || redirect === null ? homePath : redirect} />;
};
export default Unauthenticated;
