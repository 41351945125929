import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import DetailText from '@paypr/mui5-common-components/dist/components/typography/DetailText';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import { DateTime } from 'luxon';
import React from 'react';
import { Legend, LegendProps, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { LedgerReportDetailsFragment, LedgerReportValueField } from '../../generated/graphql';
import { BreakpointsHook, useBreakpoints, useColors } from '../../style/theme';
import PercentFormat, { formatPercent } from '../common/typography/PercentFormat';
import { buildPerformanceReportData, formatDateTime } from './reportHelpers';
import { ReportValueField, reportValueFields } from './reportModel';

export interface PerformanceChartProps {
  reportDetails: LedgerReportDetailsFragment;
}

interface ChartSeriesInfo {
  field: ReportValueField;
  color: string;
}

const PerformanceChart = ({ reportDetails }: PerformanceChartProps) => {
  const theme = useTheme();
  const breakpoints = useBreakpoints();

  const colors = useColors();

  const seriesInfo: ChartSeriesInfo[] = [
    {
      field: reportValueFields[LedgerReportValueField.EquityMarketPnlCumulativeRoi],
      color: colors.pink,
    },
    {
      field: reportValueFields[LedgerReportValueField.PnlCumulativeInvestmentRoi],
      color: colors.lime,
    },
  ];

  const chartData = buildPerformanceReportData(reportDetails);

  const numTicks = determineNumTicks(breakpoints);
  const tickStep = Math.floor(chartData.length / numTicks);

  if (chartData.length === 0) {
    return <DetailText>No data to display</DetailText>;
  }

  const xAxisFormatter = (value: number) => formatDateTime(DateTime.fromSeconds(value), reportDetails.timeResolution);

  const legendSettings: LegendProps = breakpoints.mediumUp
    ? {
        align: 'left',
        layout: 'vertical',
      }
    : {
        align: 'center',
        layout: 'horizontal',
      };

  return (
    <Box width="100%" height="600px">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 50,
          }}
        >
          <XAxis
            style={{ fontSize: theme.typography.body2.fontSize, fontFamily: theme.typography.body2.fontFamily }}
            dataKey="periodTimestamp"
            angle={-45}
            minTickGap={1}
            padding={{ left: 20, right: 20 }}
            tickMargin={20}
            interval={tickStep}
            tickFormatter={xAxisFormatter}
          />
          <YAxis
            style={{ fontSize: theme.typography.body2.fontSize, fontFamily: theme.typography.body2.fontFamily }}
            key="yAxis-percent"
            yAxisId="yAxis-percent"
            orientation="left"
            tickFormatter={tickFormatter}
          />

          <Tooltip content={<CustomTooltip reportDetails={reportDetails} />} />
          <Legend align={legendSettings.align} verticalAlign="top" layout={legendSettings.layout} />
          {seriesInfo.map((series) => (
            <Line
              key={`line-${series.field.valueField}`}
              connectNulls
              type="monotone"
              dataKey={series.field?.valueField ?? 'unknown'}
              name={series.field?.name ?? 'Unknown'}
              stroke={series.color}
              strokeWidth={2}
              yAxisId="yAxis-percent"
              dot={false}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
export default PerformanceChart;

const tickFormatter = (value: number) => formatPercent(value);

const determineNumTicks = ({ smallDown, mediumDown, largeDown }: BreakpointsHook) => {
  if (smallDown) {
    return 10;
  }

  if (mediumDown) {
    return 15;
  }

  if (largeDown) {
    return 20;
  }

  return 25;
};

interface CustomTooltipProps {
  reportDetails: LedgerReportDetailsFragment;
  active?: boolean;
  label?: number;
  payload?: { dataKey: string; value: number }[];
}

const CustomTooltip = ({ reportDetails, ...props }: CustomTooltipProps) => {
  const theme = useTheme();
  const { active, label: periodTimestamp, payload } = props;
  if (!active || !payload) {
    return null;
  }

  const periodDateTime = DateTime.fromSeconds(periodTimestamp);
  const periodText = formatDateTime(periodDateTime, reportDetails.timeResolution);

  return (
    <Box
      css={css`
        background-color: ${theme.palette.background.paper};
        border: 1px solid ${theme.palette.divider};
        padding: ${theme.spacing(1)};
      `}
    >
      <DetailTitle>{periodText}</DetailTitle>
      {payload.map((data, index) => (
        <DetailText key={`payload-${index}`}>
          {reportValueFields[data.dataKey].name}: <PercentFormat amount={data.value} />
        </DetailText>
      ))}
    </Box>
  );
};
