import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailInlineTitle from '@paypr/mui5-common-components/dist/components/typography/DetailInlineTitle';
import DetailInlineValue from '@paypr/mui5-common-components/dist/components/typography/DetailInlineValue';
import DetailText from '@paypr/mui5-common-components/dist/components/typography/DetailText';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React from 'react';

export interface TransferInfoPanelProps {}

const TransferInfoPanel = ({}: TransferInfoPanelProps) => (
  <>
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} lg={8}>
        <Paper>
          <DetailContainer>
            <DetailField>
              <SubSectionTitle>Wire Transfer Instructions</SubSectionTitle>
              <DetailText>Use this information to wire capital to Norrio Capital Partners Fund I, LP</DetailText>
            </DetailField>
          </DetailContainer>
        </Paper>
      </Grid>
    </Grid>
    <br />
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} sm={6} lg={4}>
        <Paper>
          <DetailContainer>
            <DetailField>
              <SubSectionTitle>Receiving Bank</SubSectionTitle>
            </DetailField>
            <DetailField>
              <DetailTitle>Beneficiary Bank</DetailTitle>
              <DetailValue>Northbrook Bank & Trust</DetailValue>
            </DetailField>
            <DetailField>
              <DetailTitle>To send in US DOLLARS</DetailTitle>
              <Box>
                <DetailInlineTitle>ABA/Routing #</DetailInlineTitle>
                <DetailInlineValue>071926184</DetailInlineValue>
              </Box>
            </DetailField>
            <DetailField>
              <DetailTitle>To send in FOREIGN CURRENCY</DetailTitle>
              <Box>
                <DetailInlineTitle>Swift Code</DetailInlineTitle>
                <DetailInlineValue>NBBTUS44</DetailInlineValue>
              </Box>
            </DetailField>
            <DetailField>
              <DetailTitle>Bank Address</DetailTitle>
              <DetailValue>
                1100 Waukegan Road
                <br />
                Northbrook, IL 60062
              </DetailValue>
            </DetailField>
          </DetailContainer>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Paper>
          <DetailContainer>
            <DetailField>
              <SubSectionTitle>Receiving Entity / For Credit to</SubSectionTitle>
            </DetailField>
            <DetailField>
              <DetailTitle>Beneficiary Name</DetailTitle>
              <DetailValue>Norrio Capital Partners Fund I, LP</DetailValue>
            </DetailField>
            <DetailField>
              <DetailTitle>Beneficiary Account #</DetailTitle>
              <DetailValue>8709467181</DetailValue>
            </DetailField>
            <DetailField>
              <DetailTitle>Beneficiary Address</DetailTitle>
              <DetailValue>
                200 Congress Ave.
                <br />
                #26H
                <br />
                Austin, TX 78701
              </DetailValue>
            </DetailField>
          </DetailContainer>
        </Paper>
      </Grid>
    </Grid>
  </>
);
export default TransferInfoPanel;
