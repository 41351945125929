import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Stage, uiState } from '../data/ui';
import { getUiConfig, UiConfig } from '../services/uiConfig';

export interface UiConfigHook {
  isProd: boolean;
  stage: Stage;
}
export const useUiConfig = (): UiConfigHook => {
  const [uiConfig, setUiConfig] = useState<UiConfig | undefined>();
  const [{ stage }, setUi] = useRecoilState(uiState);

  const isProd = stage === Stage.PROD;

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    fetchUiConfig();
  }, []);

  useEffect(() => {
    setUi((currUiState) => {
      return { ...currUiState, stage: convertToStage(uiConfig?.stage) };
    });
  }, [uiConfig]);

  const fetchUiConfig = async () => {
    setUiConfig(await getUiConfig());
  };

  return {
    isProd,
    stage,
  };
};

const convertToStage = (stage: string | undefined): Stage => {
  switch (stage) {
    case 'prod':
      return Stage.PROD;

    case 'test':
      return Stage.TEST;

    case 'local':
      return Stage.LOCAL;
  }

  if (stage) {
    console.debug('Unknown stage:', stage);
  }

  return Stage.PROD;
};
