import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailText from '@paypr/mui5-common-components/dist/components/typography/DetailText';
import React, { useEffect } from 'react';
import { useFormFields } from '../../hooks/forms';
import { isNotBlank } from '../../utils/strings';
import { redemptionFormDefaultMessage, redemptionFormSubject } from './redemptionFormHelpers';

export interface RedemptionRequestFormProps {
  defaultName?: string;
  defaultEmailAddress?: string;
  onSubmitRequest: (requestForm: RedemptionRequestFormFields) => void;
  loading?: boolean;
}

export interface RedemptionRequestFormFields {
  name: string;
  emailAddress: string;
  message: string;
}

const RedemptionRequestForm = ({
  defaultName,
  defaultEmailAddress,
  onSubmitRequest,
  loading,
}: RedemptionRequestFormProps) => {
  const { fields, handleFieldChange, resetFields } = useFormFields<RedemptionRequestFormFields>({
    name: defaultName || '',
    emailAddress: defaultEmailAddress || '',
    message: redemptionFormDefaultMessage,
  });

  useEffect(() => {
    if (fields.message !== redemptionFormDefaultMessage) {
      return;
    }

    resetFields();
  }, [defaultName, defaultEmailAddress]);

  const validateForm = () => isNotBlank(fields.name) && isNotBlank(fields.emailAddress) && isNotBlank(fields.message);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    onSubmitRequest(fields);
  };

  const mailtoLink = `mailto:support@norriocapital.com?subject=${encodeURIComponent(
    redemptionFormSubject,
  )}&body=${encodeURIComponent(redemptionFormDefaultMessage)}`;
  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} lg={8}>
        <Paper>
          <DetailContainer>
            <DetailField>
              <DetailText>
                To request a copy of the redemption request form, you can send an email to us at{' '}
                <Link href={mailtoLink}>support@norriocapital.com</Link> or submit the form below. Certain limitations
                may apply per the Limited Partnership Agreement executed by the parties.
              </DetailText>
            </DetailField>
            <Grid container justifyContent="justify" spacing={2}>
              <Grid item xs={12} sm={6}>
                <DetailField>
                  <TextField
                    name="name"
                    label="Your Name"
                    value={fields.name}
                    autoComplete="name"
                    required
                    onChange={handleFieldChange}
                    disabled={loading}
                  />
                </DetailField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DetailField>
                  <TextField
                    name="emailAddress"
                    label="Your Email Address"
                    value={fields.emailAddress}
                    autoComplete="email"
                    required
                    onChange={handleFieldChange}
                    disabled={loading}
                  />
                </DetailField>
              </Grid>
            </Grid>
            <DetailField>
              <TextField
                name="message"
                label="Message"
                value={fields.message}
                autoComplete="off"
                rows={6}
                multiline
                required
                onChange={handleFieldChange}
                disabled={loading}
              />
            </DetailField>
            <DetailField textAlign="right">
              <Button color="inherit" variant="text" disabled={loading} onClick={() => resetFields()}>
                Clear
              </Button>
              &nbsp;
              <LoaderButton loading={loading} disabled={!validateForm()} onClick={handleSubmit}>
                Submit Request
              </LoaderButton>
            </DetailField>
          </DetailContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default RedemptionRequestForm;
