import { ApolloClient, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import introspectionResult from '../generated/introspectionResult';
import { apiPost } from './auth';

const createLink = () =>
  new BatchHttpLink({
    fetch: async (input: Request, init?: RequestInit): Promise<Response> => {
      const body = JSON.parse(init?.body?.toString() || '{}');
      const response = await graphqlPost(body, true);
      const { data, headers, status } = response;

      // should be filled out as needed for advanced Apollo client needs
      return {
        headers,
        status,
        text: async () => JSON.stringify(data),
        json: () => data,
        ok: Math.floor(status / 100) === 2,
      } as Response;
    },
    batchInterval: 10,
  });

const createCache = () =>
  new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
  });

export const apolloClient = new ApolloClient({ link: createLink(), cache: createCache() });

interface GraphqlResponse {
  data: object;
  headers: object;
  status: number;
}

const graphqlPost = async (body: object | string, response?: boolean) =>
  apiPost<GraphqlResponse>('/graphql', { body, response });
