import * as React from 'react';
import AppPage from '../components/common/page/AppPage';
import DocumentsIntegration from '../components/documents/DocumentsIntegration';
import RedemptionRequestSection from '../components/redemption/RedemptionRequestSection';
import { usePageTitle } from '../hooks/page';

const DocumentsPage = () => {
  usePageTitle('Documents');

  return (
    <AppPage>
      <DocumentsIntegration />
      <br />
      <RedemptionRequestSection />
    </AppPage>
  );
};
export default DocumentsPage;
