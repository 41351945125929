import { css } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';
import { useBreakpoints } from '../../style/theme';
import { LedgerReportStandardTimeframe, ledgerReportStandardTimeframes } from './reportModel';

export interface ReportTimeframeSelectionProps {
  selected: LedgerReportStandardTimeframe;
  onSelect: (timeframe: LedgerReportStandardTimeframe) => void;
}

const standardTimeframes = [
  LedgerReportStandardTimeframe.Year,
  LedgerReportStandardTimeframe.Quarter,
  LedgerReportStandardTimeframe.Month,
  LedgerReportStandardTimeframe.Week,
  LedgerReportStandardTimeframe.Day,
];

const ReportTimeframeSelection = ({ selected, onSelect }: ReportTimeframeSelectionProps) => {
  const theme = useTheme();
  const { mediumDown } = useBreakpoints();

  const handleChange = (value) => {
    if (!value) {
      return;
    }

    onSelect(value);
  };

  if (mediumDown) {
    return (
      <FormControl size="small" margin="dense" sx={{ minWidth: theme.spacing(15), maxWidth: theme.spacing(15) }}>
        <Select value={selected} onChange={(event) => handleChange(event.target.value)}>
          {standardTimeframes.map((timeframe) => (
            <MenuItem key={timeframe} value={timeframe}>
              {ledgerReportStandardTimeframes[timeframe].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <ToggleButtonGroup size="small" value={selected} onChange={(event, value) => handleChange(value)} exclusive>
      {standardTimeframes.map((timeframe) => (
        <ToggleButton
          css={css`
            white-space: nowrap;
          `}
          key={timeframe}
          value={timeframe}
        >
          {ledgerReportStandardTimeframes[timeframe].name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
export default ReportTimeframeSelection;
