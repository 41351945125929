import { css } from '@emotion/react';
import { useTheme } from '@mui/material/styles';
import FullPage from '@paypr/mui5-common-components/dist/components/containers/FullPage';
import PageContent from '@paypr/mui5-common-components/dist/components/page/PageContent';
import React from 'react';
import PageBar from './bar/PageBar';
import AppTopNav from './nav/AppTopNav';
import PageFooter from './PageFooter';

interface AppPageProps {
  children?: React.ReactNode;
}

const AppPage = ({ children }: AppPageProps) => {
  const theme = useTheme();

  return (
    <FullPage
      css={css`
        display: block;
      `}
    >
      <PageBar />
      <AppTopNav />
      <PageContent
        css={css`
          padding-top: ${theme.spacing(3)};
        `}
      >
        {children}
        <PageFooter />
      </PageContent>
    </FullPage>
  );
};
export default AppPage;
