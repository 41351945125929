import IconButton from '@mui/material/IconButton';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import React from 'react';
import PauseIcon from './PauseIcon';
import PlayIcon from './PlayIcon';
import RefreshIcon from './RefreshIcon';
import RefreshText, { buildRefreshText } from './RefreshText';

export interface RefreshIconButtonProps extends RefreshingComponentProps {
  includeRefreshText?: boolean;
}

// 5 minutes
const defaultAutoRefresh = 5 * 60 * 1000;

export interface RefreshingComponentProps {
  refreshing?: boolean;
  loading?: boolean;
  onRefresh?: () => void;
  autoRefresh?: boolean | number;
  hide?: boolean;
  hideAutoRefresh?: boolean;
}

const RefreshIconButton = ({
  refreshing,
  loading,
  onRefresh,
  includeRefreshText,
  autoRefresh,
  hide,
  hideAutoRefresh,
}: RefreshIconButtonProps) => {
  const refreshRate = autoRefresh === true ? defaultAutoRefresh : autoRefresh;

  const [paused, setPaused] = React.useState(false);

  React.useEffect(() => {
    if (paused || !refreshRate || !onRefresh) {
      return;
    }

    const interval = setInterval(onRefresh, refreshRate);
    return () => clearInterval(interval);
  }, [refreshRate, onRefresh, paused]);

  const handleRefresh = (event) => {
    event.stopPropagation();
    onRefresh?.();
  };

  const handlePause = (event) => {
    event.stopPropagation();
    setPaused(true);
  };

  const handleResume = (event) => {
    event.stopPropagation();
    setPaused(false);
  };

  return (
    <>
      {includeRefreshText ? <RefreshText loading={loading} refreshing={refreshing} /> : null}
      {onRefresh && !hide ? (
        <>
          <TooltipItem title={buildRefreshText({ loading, refreshing }) || 'Refresh'}>
            <IconButton onClick={handleRefresh} disabled={refreshing || loading}>
              <RefreshIcon />
            </IconButton>
          </TooltipItem>
          {autoRefresh && !hideAutoRefresh ? (
            paused ? (
              <TooltipItem title="Start refreshing">
                <IconButton onClick={handleResume}>
                  <PlayIcon />
                </IconButton>
              </TooltipItem>
            ) : (
              <TooltipItem title="Stop refreshing">
                <IconButton onClick={handlePause}>
                  <PauseIcon />
                </IconButton>
              </TooltipItem>
            )
          ) : null}
        </>
      ) : null}
    </>
  );
};
export default RefreshIconButton;
