import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DarkModePreference } from '@paypr/mui5-common-components/dist/components/style/utils';
import TooltipItem from '@paypr/mui5-common-components/dist/components/typography/TooltipItem';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../hooks/auth';
import { useDarkModePreference } from '../../style/theme';
import LogoutIcon from '../common/icons/LogoutIcon';
import PasswordIcon from '../common/icons/PasswordIcon';
import SelectedIcon from '../common/icons/SelectedIcon';
import UserIcon from '../common/icons/UserIcon';
import { changePasswordPath } from '../common/routes/paths';

const UserMenu = () => {
  const navigate = useNavigate();
  const { logout, loading } = useAuthentication();
  const [mainAnchorEl, setMainAnchorEl] = useState<HTMLElement | null>(null);
  const [darkModePreference, setDarkModePreference] = useDarkModePreference();

  const handleOpenMainMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMainAnchorEl(event.currentTarget);
  };

  const closeMainMenu = () => {
    setMainAnchorEl(null);
  };

  const handleLogout = async () => {
    closeMainMenu();
    await logout();
  };

  const handleChangePassword = async () => {
    closeMainMenu();
    navigate(changePasswordPath);
  };

  const handleChangeDarkModePreference = (darkModePreference: DarkModePreference) => {
    setDarkModePreference(darkModePreference);
    closeMainMenu();
  };

  return (
    <>
      <TooltipItem title="User Settings">
        <IconButton color="inherit" onClick={handleOpenMainMenu}>
          <UserIcon data-qa="user-menu" />
        </IconButton>
      </TooltipItem>
      <Menu anchorEl={mainAnchorEl} keepMounted open={Boolean(mainAnchorEl)} onClose={closeMainMenu}>
        <MenuItem onClick={handleLogout} disabled={loading}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>

        <Divider />

        <MenuItem onClick={handleChangePassword} disabled={loading}>
          <ListItemIcon>
            <PasswordIcon />
          </ListItemIcon>
          <ListItemText>Change Password</ListItemText>
        </MenuItem>

        <Divider />

        <MenuItem disabled>
          <ListItemText>Dark Mode</ListItemText>
        </MenuItem>
        <DarkModePreferenceSelection
          title="Use Browser"
          darkModePreference={DarkModePreference.BROWSER}
          currentDarkModePreference={darkModePreference}
          onDarkModePreferenceChange={handleChangeDarkModePreference}
        />
        <DarkModePreferenceSelection
          title="Light Mode"
          darkModePreference={DarkModePreference.LIGHT}
          currentDarkModePreference={darkModePreference}
          onDarkModePreferenceChange={handleChangeDarkModePreference}
        />
        <DarkModePreferenceSelection
          title="Dark Mode"
          darkModePreference={DarkModePreference.DARK}
          currentDarkModePreference={darkModePreference}
          onDarkModePreferenceChange={handleChangeDarkModePreference}
        />
      </Menu>
    </>
  );
};
export default UserMenu;

interface DarkModePreferenceSelectionProps {
  title: string;
  darkModePreference: DarkModePreference;
  currentDarkModePreference: DarkModePreference;
  onDarkModePreferenceChange: (darkModePreference: DarkModePreference) => void;
}

const DarkModePreferenceSelection = ({
  title,
  darkModePreference,
  currentDarkModePreference,
  onDarkModePreferenceChange,
}: DarkModePreferenceSelectionProps) => {
  const selected = currentDarkModePreference === darkModePreference;
  return (
    <MenuItem onClick={() => onDarkModePreferenceChange(darkModePreference)}>
      {selected ? (
        <ListItemIcon>
          <SelectedIcon />
        </ListItemIcon>
      ) : null}
      <ListItemText inset={!selected}>{title}</ListItemText>
    </MenuItem>
  );
};
