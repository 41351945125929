import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React from 'react';
import {
  useTransferNoticeSubmissionIntegrationMutation,
  useTransferNoticeSubmissionIntegrationQuery,
} from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import TransferNoticeSubmissionForm, {
  transferNoticeSubmissionFormFundDetailFragment,
} from './TransferNoticeSubmissionForm';

export interface TransferNoticeSubmissionIntegrationProps {}

const TransferNoticeSubmissionIntegration = ({}: TransferNoticeSubmissionIntegrationProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showError, clearError } = useErrors();
  const { data, loading } = useTransferNoticeSubmissionIntegrationQuery();
  const [submitTransferNotice, { loading: submitting }] = useTransferNoticeSubmissionIntegrationMutation();

  const handleSubmitTransferNotice = async (fundId: string, originatingAccountNumber: string, amount: number) => {
    clearError();
    try {
      await submitTransferNotice({
        variables: {
          input: {
            fundId,
            originatingAccountNumber,
            amount,
          },
        },
      });

      enqueueSnackbar('Transfer Notice submitted successfully.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  return (
    <TransferNoticeSubmissionForm
      funds={data?.funds || []}
      onSubmitTransferNotice={handleSubmitTransferNotice}
      loading={loading || submitting}
    />
  );
};
export default TransferNoticeSubmissionIntegration;

gql`
  query TransferNoticeSubmissionIntegrationQuery {
    funds {
      id
      ...TransferNoticeSubmissionFormFundDetail
    }
  }

  ${transferNoticeSubmissionFormFundDetailFragment}

  mutation TransferNoticeSubmissionIntegrationMutation($input: SubmitTransferNoticeInput!) {
    submitTransferNotice(input: $input)
  }
`;
