import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { LedgerReportTimeResolution, useClientPerformanceReportIntegrationQuery } from '../../generated/graphql';
import PerformanceReport, { ReportView } from './PerformanceReport';
import { buildPerformanceReportInput } from './reportHelpers';
import {
  ledgerReportDetailsFragments,
  LedgerReportStandardTimeframe,
  ledgerReportStandardTimeframes,
} from './reportModel';

export interface ClientPerformanceReportIntegrationProps {
  fundId: string | null;
  reportView: ReportView;
}

const ClientPerformanceReportIntegration = ({ fundId, reportView }: ClientPerformanceReportIntegrationProps) => {
  const [reportTimeframe, setReportTimeframe] = React.useState(LedgerReportStandardTimeframe.Year);
  const [reportTimeResolution, setReportTimeResolution] = React.useState(
    ledgerReportStandardTimeframes[reportTimeframe].defaultTimeResolution,
  );

  const { data, loading, networkStatus, refetch } = useClientPerformanceReportIntegrationQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      reportInput: { ...buildPerformanceReportInput(reportTimeframe, reportTimeResolution), fundId },
    },
  });

  const handleSelectTimeframe = (timeframe: LedgerReportStandardTimeframe, resolution: LedgerReportTimeResolution) => {
    setReportTimeframe(timeframe);
    setReportTimeResolution(resolution);
  };

  return (
    <PerformanceReport
      title="Your Investment Performance"
      description="The performance of your investment over time"
      reportView={reportView}
      reportTimeframe={reportTimeframe}
      reportTimeResolution={reportTimeResolution}
      reportDetails={data?.clientPerformanceReport || null}
      onSelectTimeframe={handleSelectTimeframe}
      loading={loading}
      refreshing={networkStatus === NetworkStatus.refetch}
      onRefresh={() => refetch()}
      autoRefresh
    />
  );
};
export default ClientPerformanceReportIntegration;

gql`
  query ClientPerformanceReportIntegrationQuery($reportInput: ClientPerformanceReportInput!) {
    clientPerformanceReport(input: $reportInput) {
      ...LedgerReportDetailsFragment
    }
  }

  ${ledgerReportDetailsFragments}
`;
