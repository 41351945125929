import { gql } from '@apollo/client';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import DefaultTextField from '@paypr/mui5-common-components/dist/components/forms/DefaultTextField';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import NumberField from '@paypr/mui5-common-components/dist/components/forms/NumberField';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailError from '@paypr/mui5-common-components/dist/components/typography/DetailError';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailText from '@paypr/mui5-common-components/dist/components/typography/DetailText';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React, { FormEvent, useEffect } from 'react';
import { TransferNoticeSubmissionFormFundDetail } from '../../generated/graphql';
import { useFormFields } from '../../hooks/forms';
import { isNotBlank, isTextNumber, isTextPositiveFloat } from '../../utils/strings';

export interface TransferNoticeSubmissionFormProps {
  funds: readonly TransferNoticeSubmissionFormFundDetail[];
  onSubmitTransferNotice: (fundId: string, originatingAccountNumber: string, amount: number) => void;
  loading?: boolean;
}

interface TransferNoticeSubmissionFormFields {
  fundId: string;
  originatingAccountNumber: string;
  amount: string;
}

const TransferNoticeSubmissionForm = ({
  funds,
  onSubmitTransferNotice,
  loading,
}: TransferNoticeSubmissionFormProps) => {
  const defaultFundId = funds.length === 1 ? funds[0].id : '';

  const { fields, handleFieldChange, setFieldValue, resetFields } = useFormFields<TransferNoticeSubmissionFormFields>({
    fundId: defaultFundId,
    originatingAccountNumber: '',
    amount: '',
  });

  useEffect(() => {
    if (!defaultFundId) {
      return;
    }

    setFieldValue('fundId', defaultFundId);
  }, [funds]);

  const isValidAmount = isTextNumber(fields.amount) && isTextPositiveFloat(fields.amount);
  const formValid = isNotBlank(fields.originatingAccountNumber) && isValidAmount;

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (!formValid) {
      return;
    }

    onSubmitTransferNotice(fields.fundId, fields.originatingAccountNumber, parseFloat(fields.amount));

    resetFields();
  };

  const showFunds = funds.length > 1;
  const numFields = showFunds ? 3 : 2;

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} lg={8}>
        <Paper>
          <DetailContainer>
            <DetailField>
              <SubSectionTitle>Wire Transfer Tracking</SubSectionTitle>
              <DetailText>
                In addition to wiring funds, please provide to Norrio Capital Partners, LLC your bank account and the
                amount wired and click <i>Submit Transfer Notice</i>. We will use this information to track your wire
                and send you a notification when we've received it and it has been processed.
              </DetailText>
            </DetailField>
            <Grid container justifyContent="justify" spacing={2}>
              {showFunds && (
                <Grid item xs={12} sm={12 / numFields}>
                  <DetailField>
                    <DefaultTextField
                      name="fundId"
                      label="Associate with Fund"
                      value={fields.fundId}
                      required
                      onChange={handleFieldChange}
                      select
                      disabled={loading}
                    >
                      {funds.map((fund) => (
                        <MenuItem key={fund.id} value={fund.id} title={fund.legalName}>
                          {fund.name}
                        </MenuItem>
                      ))}
                    </DefaultTextField>
                  </DetailField>
                </Grid>
              )}
              <Grid item xs={12} sm={12 / numFields}>
                <DetailField>
                  <DefaultTextField
                    name="originatingAccountNumber"
                    label="Originating Bank Account Number"
                    value={fields.originatingAccountNumber}
                    required
                    onChange={handleFieldChange}
                    disabled={loading}
                  />
                </DetailField>
              </Grid>
              <Grid item xs={12} sm={12 / numFields}>
                <DetailField>
                  <NumberField
                    name="amount"
                    label="Transfer Amount"
                    autoComplete="off"
                    value={fields.amount}
                    min={0}
                    required
                    onChange={handleFieldChange}
                    disabled={loading}
                  />
                  {fields.amount.length > 0 && !isValidAmount ? (
                    <DetailError>Amount must be a number greater than 0.</DetailError>
                  ) : null}
                </DetailField>
              </Grid>
            </Grid>

            <DetailField textAlign="right">
              <Button color="inherit" variant="text" disabled={loading} onClick={() => resetFields()}>
                Clear
              </Button>
              &nbsp;
              <LoaderButton loading={loading} disabled={!formValid} onClick={handleSubmit}>
                Submit Transfer Notice
              </LoaderButton>
            </DetailField>
          </DetailContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default TransferNoticeSubmissionForm;

export const transferNoticeSubmissionFormFundDetailFragment = gql`
  fragment TransferNoticeSubmissionFormFundDetail on Fund {
    id
    name
    legalName
  }
`;
