import { DateTime } from 'luxon';
import {
  LedgerReportDetailsFragment,
  LedgerReportTimeResolution,
  LedgerReportValueField,
  PerformanceReportInput,
} from '../../generated/graphql';
import { LedgerReportStandardTimeframe, ledgerReportStandardTimeframes, ReportValue } from './reportModel';

export const buildPerformanceReportInput = (
  timeframe: LedgerReportStandardTimeframe,
  timeResolution: LedgerReportTimeResolution,
): PerformanceReportInput => {
  const startAt = determineStartAtFromStandardTimeframe(timeframe);
  const endAt = determineEndAtFromStandardTimeframe(timeframe);

  return {
    startAt: startAt.toISO(),
    endAt: endAt ? endAt.toISO() : null,
    timeResolution: timeResolution,
    timeZone: DateTime.local().zoneName,
  };
};

export const formatDateTime = (periodStartAt: DateTime, timeResolution: LedgerReportTimeResolution) => {
  switch (timeResolution) {
    case LedgerReportTimeResolution.Year:
      return periodStartAt.toFormat('yyyy');
    case LedgerReportTimeResolution.Month:
      return periodStartAt.toFormat('MMM yyyy');
    case LedgerReportTimeResolution.Week:
    case LedgerReportTimeResolution.Day:
      return periodStartAt.toLocaleString(DateTime.DATE_SHORT);

    case LedgerReportTimeResolution.Hour:
      return periodStartAt.toLocaleString(DateTime.DATETIME_SHORT);

    default:
      console.warn('Unknown time resolution: ', timeResolution);
      return periodStartAt.toLocaleString(DateTime.DATETIME_SHORT);
  }
};

export const determineStartAtFromStandardTimeframe = (timeframe: LedgerReportStandardTimeframe): DateTime => {
  switch (timeframe) {
    case LedgerReportStandardTimeframe.AllTime:
      return DateTime.fromISO('2022-01-01');

    case LedgerReportStandardTimeframe.Year:
      return DateTime.now().minus({ days: 365 }).startOf('day');

    case LedgerReportStandardTimeframe.YearToDate:
      return DateTime.now().startOf('year');

    case LedgerReportStandardTimeframe.PreviousYear:
      return DateTime.now().minus({ years: 1 }).startOf('year');

    case LedgerReportStandardTimeframe.Quarter:
      return DateTime.now().minus({ days: 90 }).startOf('day');

    case LedgerReportStandardTimeframe.QuarterToDate:
      return DateTime.now().startOf('quarter');

    case LedgerReportStandardTimeframe.PreviousQuarter:
      return DateTime.now().minus({ months: 3 }).startOf('quarter');

    case LedgerReportStandardTimeframe.Month:
      return DateTime.now().minus({ days: 30 }).startOf('day');

    case LedgerReportStandardTimeframe.MonthToDate:
      return DateTime.now().startOf('month').startOf('day');

    case LedgerReportStandardTimeframe.PreviousMonth:
      return DateTime.now().minus({ months: 1 }).startOf('month');

    case LedgerReportStandardTimeframe.Week:
      return DateTime.now().minus({ weeks: 1 }).startOf('hour');

    case LedgerReportStandardTimeframe.Day:
      return DateTime.now().minus({ days: 1 }).startOf('hour');

    default:
      console.warn('Unknown standard timeframe:', timeframe);
      return DateTime.now().minus({ weeks: 1 });
  }
};

export const determineEndAtFromStandardTimeframe = (timeframe: LedgerReportStandardTimeframe): DateTime | null => {
  switch (timeframe) {
    case LedgerReportStandardTimeframe.PreviousYear:
      return DateTime.now().minus({ years: 1 }).endOf('year');

    case LedgerReportStandardTimeframe.PreviousQuarter:
      return DateTime.now().minus({ months: 3 }).endOf('quarter');

    case LedgerReportStandardTimeframe.PreviousMonth:
      return DateTime.now().minus({ months: 1 }).endOf('month');

    default:
      return null;
  }
};

export const determineTimeFromTimeResolution = (dateTime: DateTime, timeResolution: LedgerReportTimeResolution) => {
  switch (timeResolution) {
    case LedgerReportTimeResolution.Year:
      return dateTime.startOf('year');

    case LedgerReportTimeResolution.Month:
      return dateTime.startOf('month');

    case LedgerReportTimeResolution.Week:
      return dateTime.startOf('week');

    case LedgerReportTimeResolution.Day:
      return dateTime.startOf('day');

    case LedgerReportTimeResolution.Hour:
      return dateTime.startOf('hour');

    default:
      console.warn('Unknown time resolution: ', timeResolution);
      return dateTime;
  }
};

export const timeResolutionValues = {
  [LedgerReportTimeResolution.Hour]: 1,
  [LedgerReportTimeResolution.Day]: 2,
  [LedgerReportTimeResolution.Week]: 3,
  [LedgerReportTimeResolution.Month]: 4,
  [LedgerReportTimeResolution.Year]: 5,
};

export const determineTimeResolutionFromDates = (
  startAt: DateTime,
  endAt: DateTime | null,
  timeResolution: LedgerReportTimeResolution = LedgerReportTimeResolution.Hour,
) => {
  const duration = (endAt || DateTime.now()).diff(startAt, 'days').toObject();

  let bestTimeResolution: LedgerReportTimeResolution;

  if (duration.days <= 15) {
    bestTimeResolution = LedgerReportTimeResolution.Hour;
  } else if (duration.days <= 365 * 2) {
    bestTimeResolution = LedgerReportTimeResolution.Day;
  } else {
    bestTimeResolution = LedgerReportTimeResolution.Month;
  }

  if (timeResolutionValues[timeResolution] <= timeResolutionValues[bestTimeResolution]) {
    return bestTimeResolution;
  }

  return timeResolution;
};

export const determineTimeResolutionFromStandardTimeframe = (
  timeframe: LedgerReportStandardTimeframe,
  timeResolution?: LedgerReportTimeResolution,
) => {
  const timeframeInfo = ledgerReportStandardTimeframes[timeframe];

  if (timeResolution && timeframeInfo.timeResolutionOptions.indexOf(timeResolution) >= 0) {
    return timeResolution;
  }

  return timeframeInfo.defaultTimeResolution;
};

export const buildPerformanceReportData = (reportDetails: LedgerReportDetailsFragment): ReportValue[] =>
  reportDetails.results.map((result) => {
    const findValue = (field: LedgerReportValueField) => result.values.find((value) => value.field === field);

    const getStringFieldValue = (field: LedgerReportValueField): string => {
      const foundValue = findValue(field);
      if (foundValue && 'textValue' in foundValue) {
        return foundValue.textValue;
      }

      console.warn('Unexpected string value', foundValue);
      return '';
    };

    const getNumberFieldValue = (field: LedgerReportValueField): number => {
      const foundValue = findValue(field);
      if (foundValue && 'numberValue' in foundValue) {
        return foundValue.numberValue;
      }

      console.warn('Unexpected number value', foundValue);
      return 0;
    };

    const periodDateTime = DateTime.fromISO(getStringFieldValue(LedgerReportValueField.PeriodStartAt));
    const periodTimestamp = periodDateTime.toUnixInteger();

    return {
      periodDateTime,
      periodTimestamp,
      [LedgerReportValueField.PnlCumulativeInvestmentRoi]: getNumberFieldValue(
        LedgerReportValueField.PnlCumulativeInvestmentRoi,
      ),
      [LedgerReportValueField.EquityMarketPnlCumulativeRoi]: getNumberFieldValue(
        LedgerReportValueField.EquityMarketPnlCumulativeRoi,
      ),
    };
  });
