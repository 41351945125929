import { gql, NetworkStatus } from '@apollo/client';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDocumentsIntegrationGenerateUrlMutation, useDocumentsIntegrationQuery } from '../../generated/graphql';
import DocumentsSection from './DocumentsSection';
import { DocumentClickEventHandler, documentsTableRowFragment } from './DocumentsTable';

export interface DocumentsIntegrationProps {
  onClickDocument?: DocumentClickEventHandler;
}

const DocumentsIntegration = ({ onClickDocument }: DocumentsIntegrationProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, networkStatus, refetch } = useDocumentsIntegrationQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [generateDocumentUrl, { loading: generatingDocumentUrl }] = useDocumentsIntegrationGenerateUrlMutation();

  const handleDownload = async (documentId: string) => {
    try {
      const { data } = await generateDocumentUrl({ variables: { documentId } });
      if (!data) {
        return;
      }

      await downloadFile(data.generateDocumentUrl);

      enqueueSnackbar('Download started.', { variant: 'success' });
    } catch (e) {
      console.error('Error generating document URL:', e);
    }
  };

  return (
    <>
      <DocumentsSection
        documents={data?.documents}
        onRefresh={refetch}
        loading={loading}
        refreshing={networkStatus === NetworkStatus.refetch}
        onClickDocument={onClickDocument}
        onDownloadDocument={handleDownload}
      />
      <Backdrop open={generatingDocumentUrl}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};
export default DocumentsIntegration;

const downloadFile = async (fileUrl: string) => {
  const response = await fetch(fileUrl);
  const contentDisposition = response.headers.get('Content-Disposition');
  const blob = await response.blob();
  const blobUrl = window.URL.createObjectURL(blob);

  const filename = extractFileName(contentDisposition) || 'document.pdf';

  const link = document.createElement('a');
  link.href = blobUrl;
  link.target = '_blank';
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const extractFileName = (contentDisposition: string | null | undefined): string | null => {
  if (contentDisposition) {
    const match = contentDisposition.match(/filename="(.+)"/i);
    if (match && match[1]) {
      return match[1];
    }
  }

  return null;
};

gql`
  query DocumentsIntegrationQuery {
    documents {
      id
      ...DocumentsTableRow_Document
    }
  }

  mutation DocumentsIntegrationGenerateUrlMutation($documentId: ID!) {
    generateDocumentUrl(documentId: $documentId)
  }

  ${documentsTableRowFragment}
`;
