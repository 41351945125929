import { css } from '@emotion/react';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailText from '@paypr/mui5-common-components/dist/components/typography/DetailText';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { LedgerReportDetailsFragment, LedgerReportTimeResolution } from '../../generated/graphql';
import { useBreakpoints } from '../../style/theme';
import RefreshIconButton, { RefreshingComponentProps } from '../common/icons/RefreshIconButton';
import RefreshText from '../common/icons/RefreshText';
import PerformanceChart from './PerformanceChart';
import { determineTimeResolutionFromStandardTimeframe } from './reportHelpers';
import { LedgerReportStandardTimeframe, ledgerReportStandardTimeframes } from './reportModel';
import ReportTimeframeSelection from './ReportTimeframeSelection';
import ReportTimeResolutionSelection from './ReportTimeResolutionSelection';

export interface PerformanceReportProps extends RefreshingComponentProps {
  title: string;
  description: string;
  reportView: ReportView;
  reportTimeframe: LedgerReportStandardTimeframe;
  reportTimeResolution: LedgerReportTimeResolution;
  reportDetails: LedgerReportDetailsFragment | null;
  onSelectTimeframe?: (timeframe: LedgerReportStandardTimeframe, resolution: LedgerReportTimeResolution) => void;
  pickers?: React.ReactNode;
}

export enum ReportView {
  Chart = 'chart',
  Table = 'table',
}

const PerformanceReport = ({
  title,
  description,
  reportView,
  reportTimeframe,
  reportTimeResolution,
  reportDetails,
  onSelectTimeframe,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  pickers,
}: PerformanceReportProps) => {
  const { mediumDown } = useBreakpoints();

  const handleSelectTimeframe = (newTimeframe: LedgerReportStandardTimeframe) => {
    if (!onSelectTimeframe) {
      return;
    }

    onSelectTimeframe(newTimeframe, ledgerReportStandardTimeframes[newTimeframe].defaultTimeResolution);
  };

  const handleSelectTimeResolution = (newTimeResolution: LedgerReportTimeResolution) => {
    if (!onSelectTimeframe) {
      return;
    }

    onSelectTimeframe(
      reportTimeframe,
      determineTimeResolutionFromStandardTimeframe(reportTimeframe, newTimeResolution),
    );
  };

  const reportTimeframePicker = onSelectTimeframe ? (
    <>
      &nbsp;
      <ReportTimeResolutionSelection
        timeframe={reportTimeframe}
        selected={reportTimeResolution}
        onSelect={handleSelectTimeResolution}
      />
      &nbsp;
      <ReportTimeframeSelection selected={reportTimeframe} onSelect={handleSelectTimeframe} />
    </>
  ) : null;

  return (
    <Paper
      css={css`
        min-height: 600px;
      `}
    >
      <Toolbar>
        <SectionTitle>{title}</SectionTitle>
        <HorizontalAutoSpacer />
        {!mediumDown ? pickers : null}
        {!mediumDown ? reportTimeframePicker : null}
        <RefreshIconButton
          loading={loading}
          refreshing={refreshing}
          onRefresh={onRefresh}
          autoRefresh={autoRefresh}
          hideAutoRefresh
        />
      </Toolbar>
      <DetailContainer>
        {mediumDown ? pickers : null}
        {mediumDown ? reportTimeframePicker : null}
        <DetailText>{description}</DetailText>
        <br />
        {reportDetails ? (
          <>
            {reportView === ReportView.Chart ? (
              <PerformanceChart reportDetails={reportDetails} />
            ) : (
              <>Table Goes Here</>
            )}
            <DetailText variant="caption">
              DISCLAIMER: The information displayed is estimated performance and subject to a number of risks and
              uncertainties, some of which are beyond the control of Norrio Capital Partners, LLC.
            </DetailText>
          </>
        ) : (
          <RefreshText refreshing={refreshing} loading={loading} />
        )}
      </DetailContainer>
    </Paper>
  );
};
export default PerformanceReport;
