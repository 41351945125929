import AppRoutes from './components/common/routes/AppRoutes';
import { useAuthenticationSetup } from './hooks/auth';
import { useAppVersion } from './hooks/version';

const AppRoot = () => {
  useAppVersion();
  useAuthenticationSetup();

  return <AppRoutes />;
};
export default AppRoot;
