import { css } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';
import { LedgerReportTimeResolution } from '../../generated/graphql';
import { useBreakpoints } from '../../style/theme';
import {
  LedgerReportStandardTimeframe,
  ledgerReportStandardTimeframes,
  ledgerReportTimeResolutions,
} from './reportModel';

export interface ReportTimeResolutionSelectionProps {
  timeframe: LedgerReportStandardTimeframe;
  selected: LedgerReportTimeResolution;
  onSelect: (timeResolution: LedgerReportTimeResolution) => void;
}

const ReportTimeResolutionSelection = ({ timeframe, selected, onSelect }: ReportTimeResolutionSelectionProps) => {
  const theme = useTheme();
  const { mediumDown } = useBreakpoints();

  const handleChange = (value) => {
    if (!value) {
      return;
    }

    onSelect(value);
  };

  const timeResolutions = ledgerReportStandardTimeframes[timeframe].timeResolutionOptions;
  if (!timeResolutions || timeResolutions.length < 2) {
    return null;
  }

  if (mediumDown) {
    return (
      <FormControl size="small" margin="dense" sx={{ minWidth: theme.spacing(15), maxWidth: theme.spacing(15) }}>
        <Select value={selected} onChange={(event) => handleChange(event.target.value)}>
          {timeResolutions.map((timeResolution) => (
            <MenuItem key={timeResolution} value={timeResolution}>
              {ledgerReportTimeResolutions[timeResolution].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <ToggleButtonGroup size="small" value={selected} onChange={(event, value) => handleChange(value)} exclusive>
      {timeResolutions.map((timeResolution) => (
        <ToggleButton
          css={css`
            white-space: nowrap;
          `}
          key={timeResolution}
          value={timeResolution}
        >
          {ledgerReportTimeResolutions[timeResolution].name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
export default ReportTimeResolutionSelection;
