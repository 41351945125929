import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { ClientTransactionsTableRow_ClientTransaction } from '../../generated/graphql';
import RefreshIconButton, { RefreshingComponentProps } from '../common/icons/RefreshIconButton';
import ClientTransactionsTable, { ClientTransactionClickEventHandler } from './ClientTransactionsTable';

export interface ClientTransactionsSectionProps extends RefreshingComponentProps {
  transactions?: readonly ClientTransactionsTableRow_ClientTransaction[];
  hideClient?: boolean;
  hideFund?: boolean;
  onClickClientTransaction?: ClientTransactionClickEventHandler;
}

const ClientTransactionsSection = ({
  transactions,
  hideFund,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
  onClickClientTransaction,
}: ClientTransactionsSectionProps) => (
  <Paper>
    <Toolbar>
      <SectionTitle>Transactions</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    <ClientTransactionsTable
      transactions={transactions}
      hideFund={hideFund}
      onClickClientTransactionRow={onClickClientTransaction}
    />
  </Paper>
);
export default ClientTransactionsSection;
