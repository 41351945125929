import DialogActions from '@mui/material/DialogActions/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DefaultTextField from '@paypr/mui5-common-components/dist/components/forms/DefaultTextField';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import * as React from 'react';
import { FormEvent } from 'react';
import { useFormFields } from '../../hooks/forms';

interface LoginFormFields {
  email: string;
  password: string;
}

interface LoginFormProps {
  onLogin: (email: string, password: string) => void;
  loading?: boolean;
  disabled?: boolean;
}

const LoginForm = ({ onLogin, loading, disabled }: LoginFormProps) => {
  const { fields, handleFieldChange } = useFormFields<LoginFormFields>({ email: '', password: '' });

  const validateForm = () => fields.email.length > 0 && fields.password.length > 0;

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    onLogin(fields.email, fields.password);
  };

  return (
    <form onSubmit={handleSubmit} data-qa="login-form">
      <DialogContent>
        <DefaultTextField
          autoFocus
          name="email"
          type="email"
          label="Email"
          autoComplete="email"
          value={fields.email}
          required
          onChange={handleFieldChange}
        />
        <DefaultTextField
          name="password"
          type="password"
          label="Password"
          autoComplete="current-password"
          value={fields.password}
          required
          onChange={handleFieldChange}
        />
      </DialogContent>
      <DialogActions>
        <LoaderButton
          type="submit"
          data-qa="login-button"
          loading={loading}
          disabled={disabled || !validateForm()}
          color="primary"
        >
          Login
        </LoaderButton>
      </DialogActions>
    </form>
  );
};
export default LoginForm;
