import { useSnackbar } from 'notistack';
import React from 'react';
import { useContactUsIntegrationMutation, useContactUsIntegrationQuery } from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import { redemptionFormSubject } from './redemptionFormHelpers';
import RedemptionRequestForm, { RedemptionRequestFormFields } from './RedemptionRequestForm';

export interface RedemptionRequestIntegrationProps {}

const RedemptionRequestIntegration = ({}: RedemptionRequestIntegrationProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showError, clearError } = useErrors();

  const { data } = useContactUsIntegrationQuery();
  const [submitContactUs, { loading }] = useContactUsIntegrationMutation();

  const handleSubmitRequest = async (requestForm: RedemptionRequestFormFields) => {
    clearError();
    try {
      await submitContactUs({
        variables: {
          input: {
            ...requestForm,
            subject: redemptionFormSubject,
          },
        },
      });

      enqueueSnackbar('Request sent successfully.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  return (
    <RedemptionRequestForm
      defaultName={data?.clientInfo?.contact.firstName}
      defaultEmailAddress={data?.clientInfo?.contact?.emailAddress}
      onSubmitRequest={handleSubmitRequest}
      loading={loading}
    />
  );
};
export default RedemptionRequestIntegration;
