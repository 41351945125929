import { HubCapsule } from '@aws-amplify/core/src/Hub';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify, API, Auth, Hub } from 'aws-amplify';
import memoize from 'fast-memoize';
import { getUiConfig, UiConfig } from './uiConfig';

const setupAmplify = ({ api, auth }: UiConfig) => {
  // Amplify.Logger.LOG_LEVEL = 'VERBOSE';
  Amplify.register(Auth);
  Amplify.register(API);
  Amplify.register(API.Credentials);
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: auth.region,
      userPoolId: auth.userPoolId,
      identityPoolId: auth.identityPoolId,
      userPoolWebClientId: auth.userPoolWebClientId,
    },
    API: {
      endpoints: [
        {
          name: 'api',
          endpoint: api.baseUrl,
          region: api.region,
        },
      ],
    },
  });

  Hub.listen('auth', authListener);
};

const authListener = async (data: HubCapsule) => {
  switch (data.payload.event) {
    case 'autoSignIn':
    case 'signIn':
      await handleSignedIn();
      return;

    case 'tokenRefresh':
      console.debug('tokenRefresh', data);
      return;

    case 'signOut':
    case 'tokenRefresh_failure':
      await handleSignedOut();
      return;
  }
};

const configureAmplify = memoize(async () => {
  setupAmplify(await getUiConfig());
});

const getCurrentSession = async (): Promise<CognitoUserSession | null> => {
  await configureAmplify();

  try {
    return await Auth.currentSession();
  } catch (e) {
    if (e === 'No current user') {
      return null;
    }

    throw e;
  }
};

export const isUserLoggedIn = async () => Boolean(await getCurrentSession());

interface AuthUserInfo {
  id?: string;
  username: string;
  attributes: {
    email: string;
    name?: string | null;
  };
}

export interface UserInfo {
  id: string;
  email: string;
  name: string | null;
}

export const getCurrentUser = async (): Promise<UserInfo | null> => {
  await configureAmplify();

  const user: AuthUserInfo | null = await Auth.currentUserInfo();
  if (!user) {
    return null;
  }

  const {
    attributes: { email, name },
  } = user;

  return {
    email,
    name: name || null,
    id: user.id || email,
  };
};

const signedInActions: ((user: UserInfo) => void)[] = [];

export const addSignedInAction = (action: (user: UserInfo) => void) => {
  signedInActions.push(action);
};

const handleSignedIn = async () => {
  const user = await getCurrentUser();
  if (!user) {
    console.info('Not signed in');
    return;
  }

  console.info('Signed in:', user);
  signedInActions.forEach((action) => action(user));
};

const signedOutActions: (() => void)[] = [];

export const addSignedOutAction = (action: () => void) => {
  signedOutActions.push(action);
};

const handleSignedOut = () => {
  console.info('Signed out');

  signedOutActions.forEach((action) => action());
};

export const login = async (email: string, password: string): Promise<void> => {
  await Auth.signIn(email, password);
};

export const logout = async () => {
  await Auth.signOut();
};

export const changePassword = async (oldPassword: string, newPassword: string) => {
  const currentUser = await Auth.currentAuthenticatedUser();
  if (!currentUser) {
    throw new Error('Must be logged in');
  }
  await Auth.changePassword(currentUser, oldPassword, newPassword);
};

const getFromApi = async <T>(apiName: string, path: string, init: { response?: boolean }): Promise<T> => {
  await configureAmplify();

  return API.get(apiName, path, init);
};

const postToApi = async <T>(
  apiName: string,
  path: string,
  init: { body: string | object; queryStringParameters?: { [key: string]: string }; response?: boolean },
): Promise<T> => {
  await configureAmplify();

  return API.post(apiName, path, init);
};

export const apiGet = async <T>(
  path: string,
  request: { queryStringParameters?: { [key: string]: string }; response?: boolean } = {},
) => getFromApi<T>('api', path, request);

export const apiPost = async <T>(path: string, request: { body: object | string; response?: boolean }) =>
  postToApi<T>('api', path, request);
