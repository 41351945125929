import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date (isoformat) */
  Date: { input: any; output: any; }
  /** Date with time (isoformat) */
  DateTime: { input: any; output: any; }
  TimeZone: { input: any; output: any; }
  /** Represents NULL values */
  Void: { input: any; output: any; }
};

export type ClientContact = {
  readonly __typename: 'ClientContact';
  readonly city: Scalars['String']['output'];
  readonly country: Scalars['String']['output'];
  readonly emailAddress: Scalars['String']['output'];
  readonly firstName: Scalars['String']['output'];
  readonly fullName: Scalars['String']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly phoneNumber: Scalars['String']['output'];
  readonly postalCode: Scalars['String']['output'];
  readonly state: Maybe<Scalars['String']['output']>;
  readonly streetAddress1: Scalars['String']['output'];
  readonly streetAddress2: Maybe<Scalars['String']['output']>;
};

export type ClientInfo = {
  readonly __typename: 'ClientInfo';
  readonly accountNumber: Scalars['String']['output'];
  readonly contact: ClientContact;
  readonly firstName: Scalars['String']['output'];
  readonly fullName: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly lastName: Scalars['String']['output'];
  readonly stats: ClientStats;
};


export type ClientInfoStatsArgs = {
  fundId?: InputMaybe<Scalars['ID']['input']>;
  timezone?: InputMaybe<Scalars['TimeZone']['input']>;
};

export type ClientPerformanceReportInput = {
  readonly endAt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly fundId?: InputMaybe<Scalars['ID']['input']>;
  /** @deprecated No longer valid */
  readonly includeInvestment?: Scalars['Boolean']['input'];
  readonly startAt: Scalars['DateTime']['input'];
  readonly timeResolution: LedgerReportTimeResolution;
  readonly timeZone?: InputMaybe<Scalars['TimeZone']['input']>;
};

export type ClientStats = {
  readonly __typename: 'ClientStats';
  readonly currentValue: Scalars['Float']['output'];
  readonly deposits: Scalars['Float']['output'];
  readonly investment: Scalars['Float']['output'];
  readonly pnlCumulativeRoi: Scalars['Float']['output'];
  readonly pnlCumulativeValue: Scalars['Float']['output'];
  /** @deprecated No longer valid */
  readonly realizedGainsCumulativeRoi: Scalars['Float']['output'];
  /** @deprecated No longer valid */
  readonly realizedGainsCumulativeValue: Scalars['Float']['output'];
  /** @deprecated No longer valid */
  readonly unrealizedGainsCumulativeRoi: Scalars['Float']['output'];
  /** @deprecated No longer valid */
  readonly unrealizedGainsCumulativeValue: Scalars['Float']['output'];
  readonly withdrawals: Scalars['Float']['output'];
};

export type ClientTransaction = {
  readonly __typename: 'ClientTransaction';
  readonly amount: Scalars['Float']['output'];
  readonly deposits: Scalars['Float']['output'];
  readonly fees: Scalars['Float']['output'];
  readonly fund: Fund;
  readonly id: Scalars['ID']['output'];
  readonly investment: Scalars['Float']['output'];
  readonly occurredAt: Scalars['DateTime']['output'];
  readonly type: ClientTransactionType;
  readonly value: Scalars['Float']['output'];
  readonly withdrawals: Scalars['Float']['output'];
};

export enum ClientTransactionType {
  Deposit = 'DEPOSIT',
  ManagementFee = 'MANAGEMENT_FEE',
  PerformanceFee = 'PERFORMANCE_FEE',
  Withdrawal = 'WITHDRAWAL'
}

export type Document = {
  readonly __typename: 'Document';
  readonly fund: Fund;
  readonly id: Scalars['ID']['output'];
  readonly periodStartOn: Scalars['Date']['output'];
  readonly type: DocumentType;
};

export enum DocumentType {
  K1 = 'K1',
  Statement = 'Statement'
}

export type Fund = {
  readonly __typename: 'Fund';
  readonly description: Scalars['String']['output'];
  readonly id: Scalars['ID']['output'];
  readonly legalName: Scalars['String']['output'];
  readonly name: Scalars['String']['output'];
  readonly performanceReport: LedgerReportDetails;
};


export type FundPerformanceReportArgs = {
  input: PerformanceReportInput;
};

export type LedgerReportDetails = {
  readonly __typename: 'LedgerReportDetails';
  readonly endAt: Scalars['DateTime']['output'];
  readonly results: ReadonlyArray<LedgerReportResult>;
  readonly startAt: Scalars['DateTime']['output'];
  readonly timeResolution: LedgerReportTimeResolution;
  readonly timeZone: Scalars['TimeZone']['output'];
  readonly values: ReadonlyArray<LedgerReportValueField>;
};

export type LedgerReportResult = {
  readonly __typename: 'LedgerReportResult';
  readonly values: ReadonlyArray<LedgerReportResultFieldNumberValueLedgerReportResultFieldTextValue>;
};

export type LedgerReportResultFieldNumberValue = {
  readonly __typename: 'LedgerReportResultFieldNumberValue';
  readonly field: LedgerReportValueField;
  readonly value: Scalars['Float']['output'];
};

export type LedgerReportResultFieldNumberValueLedgerReportResultFieldTextValue = LedgerReportResultFieldNumberValue | LedgerReportResultFieldTextValue;

export type LedgerReportResultFieldTextValue = {
  readonly __typename: 'LedgerReportResultFieldTextValue';
  readonly field: LedgerReportValueField;
  readonly value: Scalars['String']['output'];
};

export enum LedgerReportTimeResolution {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum LedgerReportValueField {
  AllocatedFunds = 'ALLOCATED_FUNDS',
  AssetAmount = 'ASSET_AMOUNT',
  AssetCost = 'ASSET_COST',
  AssetDeploymentPercent = 'ASSET_DEPLOYMENT_PERCENT',
  AssetPortfolioPercent = 'ASSET_PORTFOLIO_PERCENT',
  AssetPrice = 'ASSET_PRICE',
  AssetSize = 'ASSET_SIZE',
  AssetValue = 'ASSET_VALUE',
  AssetValueChange = 'ASSET_VALUE_CHANGE',
  AverageAssetCost = 'AVERAGE_ASSET_COST',
  ClientDeposits = 'CLIENT_DEPOSITS',
  ClientFees = 'CLIENT_FEES',
  ClientFeesChange = 'CLIENT_FEES_CHANGE',
  ClientFundPercent = 'CLIENT_FUND_PERCENT',
  ClientHoldings = 'CLIENT_HOLDINGS',
  ClientInvestment = 'CLIENT_INVESTMENT',
  ClientManagementFees = 'CLIENT_MANAGEMENT_FEES',
  ClientManagementFeesChange = 'CLIENT_MANAGEMENT_FEES_CHANGE',
  ClientPerformanceFees = 'CLIENT_PERFORMANCE_FEES',
  ClientPerformanceFeesChange = 'CLIENT_PERFORMANCE_FEES_CHANGE',
  ClientValue = 'CLIENT_VALUE',
  ClientWithdrawals = 'CLIENT_WITHDRAWALS',
  CryptoMarketPnlCumulativeRoi = 'CRYPTO_MARKET_PNL_CUMULATIVE_ROI',
  CryptoMarketPnlCumulativeValue = 'CRYPTO_MARKET_PNL_CUMULATIVE_VALUE',
  CryptoMarketPnlRoiChange = 'CRYPTO_MARKET_PNL_ROI_CHANGE',
  CryptoMarketPnlValueChange = 'CRYPTO_MARKET_PNL_VALUE_CHANGE',
  EquityMarketPnlCumulativeRoi = 'EQUITY_MARKET_PNL_CUMULATIVE_ROI',
  EquityMarketPnlCumulativeValue = 'EQUITY_MARKET_PNL_CUMULATIVE_VALUE',
  EquityMarketPnlRoiChange = 'EQUITY_MARKET_PNL_ROI_CHANGE',
  EquityMarketPnlValueChange = 'EQUITY_MARKET_PNL_VALUE_CHANGE',
  HighWaterMark = 'HIGH_WATER_MARK',
  IdealClientFees = 'IDEAL_CLIENT_FEES',
  IdealClientFeesAdjustments = 'IDEAL_CLIENT_FEES_ADJUSTMENTS',
  IdealClientFeesChange = 'IDEAL_CLIENT_FEES_CHANGE',
  IdealClientInvestment = 'IDEAL_CLIENT_INVESTMENT',
  IdealClientManagementFees = 'IDEAL_CLIENT_MANAGEMENT_FEES',
  IdealClientManagementFeesChange = 'IDEAL_CLIENT_MANAGEMENT_FEES_CHANGE',
  IdealClientPerformanceFees = 'IDEAL_CLIENT_PERFORMANCE_FEES',
  IdealClientPerformanceFeesChange = 'IDEAL_CLIENT_PERFORMANCE_FEES_CHANGE',
  IdealHighWaterMark = 'IDEAL_HIGH_WATER_MARK',
  IdealNetValue = 'IDEAL_NET_VALUE',
  ManagedCurrency = 'MANAGED_CURRENCY',
  MarketPnlCumulativeRoi = 'MARKET_PNL_CUMULATIVE_ROI',
  MarketPnlCumulativeValue = 'MARKET_PNL_CUMULATIVE_VALUE',
  MarketPnlRoiChange = 'MARKET_PNL_ROI_CHANGE',
  MarketPnlValueChange = 'MARKET_PNL_VALUE_CHANGE',
  NetValue = 'NET_VALUE',
  NumTrades = 'NUM_TRADES',
  NumTradesCumulativeValue = 'NUM_TRADES_CUMULATIVE_VALUE',
  NumTradesValueChange = 'NUM_TRADES_VALUE_CHANGE',
  PeriodEndAt = 'PERIOD_END_AT',
  PeriodNumber = 'PERIOD_NUMBER',
  PeriodStartAt = 'PERIOD_START_AT',
  PnlCumulativeIdealRoi = 'PNL_CUMULATIVE_IDEAL_ROI',
  PnlCumulativeIdealValue = 'PNL_CUMULATIVE_IDEAL_VALUE',
  PnlCumulativeInvestmentRoi = 'PNL_CUMULATIVE_INVESTMENT_ROI',
  PnlCumulativeRawValue = 'PNL_CUMULATIVE_RAW_VALUE',
  PnlCumulativeRoi = 'PNL_CUMULATIVE_ROI',
  PnlCumulativeValue = 'PNL_CUMULATIVE_VALUE',
  PnlIdealRoiChange = 'PNL_IDEAL_ROI_CHANGE',
  PnlIdealValueChange = 'PNL_IDEAL_VALUE_CHANGE',
  PnlInvestmentRoiChange = 'PNL_INVESTMENT_ROI_CHANGE',
  PnlLatestRoi = 'PNL_LATEST_ROI',
  PnlRawValueChange = 'PNL_RAW_VALUE_CHANGE',
  PnlRoiChange = 'PNL_ROI_CHANGE',
  PnlValueChange = 'PNL_VALUE_CHANGE',
  PortfolioFundPercent = 'PORTFOLIO_FUND_PERCENT',
  PortfolioValue = 'PORTFOLIO_VALUE',
  RealizedGainsCumulativeIdealRoi = 'REALIZED_GAINS_CUMULATIVE_IDEAL_ROI',
  RealizedGainsCumulativeIdealValue = 'REALIZED_GAINS_CUMULATIVE_IDEAL_VALUE',
  RealizedGainsCumulativeInvestmentRoi = 'REALIZED_GAINS_CUMULATIVE_INVESTMENT_ROI',
  RealizedGainsCumulativeRawValue = 'REALIZED_GAINS_CUMULATIVE_RAW_VALUE',
  RealizedGainsCumulativeRoi = 'REALIZED_GAINS_CUMULATIVE_ROI',
  RealizedGainsCumulativeValue = 'REALIZED_GAINS_CUMULATIVE_VALUE',
  RealizedGainsIdealRoiChange = 'REALIZED_GAINS_IDEAL_ROI_CHANGE',
  RealizedGainsIdealValueChange = 'REALIZED_GAINS_IDEAL_VALUE_CHANGE',
  RealizedGainsInvestmentRoiChange = 'REALIZED_GAINS_INVESTMENT_ROI_CHANGE',
  RealizedGainsRawValueChange = 'REALIZED_GAINS_RAW_VALUE_CHANGE',
  RealizedGainsRoiChange = 'REALIZED_GAINS_ROI_CHANGE',
  RealizedGainsValueChange = 'REALIZED_GAINS_VALUE_CHANGE',
  TurnoverCumulativeRoi = 'TURNOVER_CUMULATIVE_ROI',
  TurnoverCumulativeValue = 'TURNOVER_CUMULATIVE_VALUE',
  TurnoverPercent = 'TURNOVER_PERCENT',
  TurnoverRoiChange = 'TURNOVER_ROI_CHANGE',
  TurnoverValue = 'TURNOVER_VALUE',
  TurnoverValueChange = 'TURNOVER_VALUE_CHANGE',
  UnallocatedFunds = 'UNALLOCATED_FUNDS',
  UnallocatedHoldings = 'UNALLOCATED_HOLDINGS',
  UnallocatedInvestment = 'UNALLOCATED_INVESTMENT',
  UnrealizedGainsCumulativeInvestmentRoi = 'UNREALIZED_GAINS_CUMULATIVE_INVESTMENT_ROI',
  UnrealizedGainsCumulativeRoi = 'UNREALIZED_GAINS_CUMULATIVE_ROI',
  UnrealizedGainsCumulativeValue = 'UNREALIZED_GAINS_CUMULATIVE_VALUE',
  UnrealizedGainsInvestmentRoiChange = 'UNREALIZED_GAINS_INVESTMENT_ROI_CHANGE',
  UnrealizedGainsRoiChange = 'UNREALIZED_GAINS_ROI_CHANGE',
  UnrealizedGainsValueChange = 'UNREALIZED_GAINS_VALUE_CHANGE',
  UsdAmount = 'USD_AMOUNT',
  UsdAmountChange = 'USD_AMOUNT_CHANGE',
  UsdBalance = 'USD_BALANCE'
}

export type Mutation = {
  readonly __typename: 'Mutation';
  readonly generateDocumentUrl: Scalars['String']['output'];
  readonly submitContactUs: Maybe<Scalars['Void']['output']>;
  readonly submitTransferNotice: Maybe<Scalars['Void']['output']>;
};


export type MutationGenerateDocumentUrlArgs = {
  documentId: Scalars['ID']['input'];
};


export type MutationSubmitContactUsArgs = {
  input: SubmitContactUsInput;
};


export type MutationSubmitTransferNoticeArgs = {
  input: SubmitTransferNoticeInput;
};

export type PerformanceReportInput = {
  readonly endAt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly startAt: Scalars['DateTime']['input'];
  readonly timeResolution: LedgerReportTimeResolution;
  readonly timeZone?: InputMaybe<Scalars['TimeZone']['input']>;
};

export type Query = {
  readonly __typename: 'Query';
  readonly clientInfo: ClientInfo;
  readonly clientPerformanceReport: LedgerReportDetails;
  readonly clientTransactions: ReadonlyArray<ClientTransaction>;
  readonly document: Maybe<Document>;
  readonly documents: ReadonlyArray<Document>;
  readonly fund: Maybe<Fund>;
  /** @deprecated Use Fund.performanceReport instead */
  readonly fundPerformanceReport: LedgerReportDetails;
  readonly funds: ReadonlyArray<Fund>;
  readonly transferNotices: ReadonlyArray<TransferNotice>;
};


export type QueryClientPerformanceReportArgs = {
  input: ClientPerformanceReportInput;
};


export type QueryDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFundArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFundPerformanceReportArgs = {
  input: PerformanceReportInput;
};

export type SubmitContactUsInput = {
  readonly emailAddress: Scalars['String']['input'];
  readonly message: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly subject: Scalars['String']['input'];
};

export type SubmitTransferNoticeInput = {
  readonly amount: Scalars['Float']['input'];
  readonly fundId: Scalars['String']['input'];
  readonly originatingAccountNumber: Scalars['String']['input'];
};

export type TransferNotice = {
  readonly __typename: 'TransferNotice';
  readonly amount: Scalars['Float']['output'];
  readonly createdAt: Scalars['DateTime']['output'];
  readonly effectiveAt: Maybe<Scalars['DateTime']['output']>;
  readonly fund: Fund;
  readonly id: Scalars['ID']['output'];
  readonly originatingAccountNumber: Scalars['String']['output'];
  readonly processedAt: Maybe<Scalars['DateTime']['output']>;
};

export type ClientInfoDetails = { readonly __typename: 'ClientInfo', readonly id: string, readonly accountNumber: string, readonly fullName: string, readonly contact: { readonly __typename: 'ClientContact', readonly streetAddress1: string, readonly streetAddress2: string | null, readonly city: string, readonly state: string | null, readonly postalCode: string, readonly country: string } };

export type ClientInfoPanelIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientInfoPanelIntegrationQuery = { readonly __typename: 'Query', readonly clientInfo: { readonly __typename: 'ClientInfo', readonly id: string, readonly accountNumber: string, readonly fullName: string, readonly contact: { readonly __typename: 'ClientContact', readonly streetAddress1: string, readonly streetAddress2: string | null, readonly city: string, readonly state: string | null, readonly postalCode: string, readonly country: string } } };

export type ClientPerformanceDetails = { readonly __typename: 'ClientInfo', readonly id: string, readonly stats: { readonly __typename: 'ClientStats', readonly deposits: number, readonly withdrawals: number, readonly currentValue: number, readonly pnlCumulativeRoi: number, readonly pnlCumulativeValue: number } };

export type ClientPerformanceStatsIntegrationFundsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientPerformanceStatsIntegrationFundsQuery = { readonly __typename: 'Query', readonly funds: ReadonlyArray<{ readonly __typename: 'Fund', readonly id: string, readonly name: string, readonly legalName: string }> };

export type ClientPerformanceStatsIntegrationQueryVariables = Exact<{
  fundId: InputMaybe<Scalars['ID']['input']>;
  timezone: InputMaybe<Scalars['TimeZone']['input']>;
}>;


export type ClientPerformanceStatsIntegrationQuery = { readonly __typename: 'Query', readonly clientInfo: { readonly __typename: 'ClientInfo', readonly id: string, readonly stats: { readonly __typename: 'ClientStats', readonly deposits: number, readonly withdrawals: number, readonly currentValue: number, readonly pnlCumulativeRoi: number, readonly pnlCumulativeValue: number } } };

export type ContactUsIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type ContactUsIntegrationQuery = { readonly __typename: 'Query', readonly clientInfo: { readonly __typename: 'ClientInfo', readonly id: string, readonly fullName: string, readonly contact: { readonly __typename: 'ClientContact', readonly firstName: string, readonly emailAddress: string } } };

export type ContactUsIntegrationMutationVariables = Exact<{
  input: SubmitContactUsInput;
}>;


export type ContactUsIntegrationMutation = { readonly __typename: 'Mutation', readonly submitContactUs: any | null };

export type DocumentsIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentsIntegrationQuery = { readonly __typename: 'Query', readonly documents: ReadonlyArray<{ readonly __typename: 'Document', readonly id: string, readonly type: DocumentType, readonly periodStartOn: any, readonly fund: { readonly __typename: 'Fund', readonly id: string, readonly name: string, readonly legalName: string } }> };

export type DocumentsIntegrationGenerateUrlMutationVariables = Exact<{
  documentId: Scalars['ID']['input'];
}>;


export type DocumentsIntegrationGenerateUrlMutation = { readonly __typename: 'Mutation', readonly generateDocumentUrl: string };

export type DocumentsTableRow_Document = { readonly __typename: 'Document', readonly id: string, readonly type: DocumentType, readonly periodStartOn: any, readonly fund: { readonly __typename: 'Fund', readonly id: string, readonly name: string, readonly legalName: string } };

export type ClientPerformanceReportIntegrationQueryVariables = Exact<{
  reportInput: ClientPerformanceReportInput;
}>;


export type ClientPerformanceReportIntegrationQuery = { readonly __typename: 'Query', readonly clientPerformanceReport: { readonly __typename: 'LedgerReportDetails', readonly timeResolution: LedgerReportTimeResolution, readonly startAt: any, readonly endAt: any, readonly timeZone: any, readonly values: ReadonlyArray<LedgerReportValueField>, readonly results: ReadonlyArray<{ readonly __typename: 'LedgerReportResult', readonly values: ReadonlyArray<{ readonly __typename: 'LedgerReportResultFieldNumberValue', readonly field: LedgerReportValueField, readonly numberValue: number } | { readonly __typename: 'LedgerReportResultFieldTextValue', readonly field: LedgerReportValueField, readonly textValue: string }> }> } };

export type FundPerformanceReportIntegrationFundsQueryVariables = Exact<{ [key: string]: never; }>;


export type FundPerformanceReportIntegrationFundsQuery = { readonly __typename: 'Query', readonly funds: ReadonlyArray<{ readonly __typename: 'Fund', readonly id: string, readonly name: string, readonly legalName: string }> };

export type FundPerformanceReportIntegrationFundPerformanceQueryVariables = Exact<{
  fundId: Scalars['ID']['input'];
  reportInput: PerformanceReportInput;
}>;


export type FundPerformanceReportIntegrationFundPerformanceQuery = { readonly __typename: 'Query', readonly fund: { readonly __typename: 'Fund', readonly performanceReport: { readonly __typename: 'LedgerReportDetails', readonly timeResolution: LedgerReportTimeResolution, readonly startAt: any, readonly endAt: any, readonly timeZone: any, readonly values: ReadonlyArray<LedgerReportValueField>, readonly results: ReadonlyArray<{ readonly __typename: 'LedgerReportResult', readonly values: ReadonlyArray<{ readonly __typename: 'LedgerReportResultFieldNumberValue', readonly field: LedgerReportValueField, readonly numberValue: number } | { readonly __typename: 'LedgerReportResultFieldTextValue', readonly field: LedgerReportValueField, readonly textValue: string }> }> } } | null };

export type FundPerformanceReportIntegrationQueryVariables = Exact<{
  reportInput: PerformanceReportInput;
}>;


export type FundPerformanceReportIntegrationQuery = { readonly __typename: 'Query', readonly fundPerformanceReport: { readonly __typename: 'LedgerReportDetails', readonly timeResolution: LedgerReportTimeResolution, readonly startAt: any, readonly endAt: any, readonly timeZone: any, readonly values: ReadonlyArray<LedgerReportValueField>, readonly results: ReadonlyArray<{ readonly __typename: 'LedgerReportResult', readonly values: ReadonlyArray<{ readonly __typename: 'LedgerReportResultFieldNumberValue', readonly field: LedgerReportValueField, readonly numberValue: number } | { readonly __typename: 'LedgerReportResultFieldTextValue', readonly field: LedgerReportValueField, readonly textValue: string }> }> } };

export type ReportFundSelectionFundDetails = { readonly __typename: 'Fund', readonly id: string, readonly name: string, readonly legalName: string };

export type LedgerReportDetailsFragment = { readonly __typename: 'LedgerReportDetails', readonly timeResolution: LedgerReportTimeResolution, readonly startAt: any, readonly endAt: any, readonly timeZone: any, readonly values: ReadonlyArray<LedgerReportValueField>, readonly results: ReadonlyArray<{ readonly __typename: 'LedgerReportResult', readonly values: ReadonlyArray<{ readonly __typename: 'LedgerReportResultFieldNumberValue', readonly field: LedgerReportValueField, readonly numberValue: number } | { readonly __typename: 'LedgerReportResultFieldTextValue', readonly field: LedgerReportValueField, readonly textValue: string }> }> };

export type NumberValueDetails = { readonly __typename: 'LedgerReportResultFieldNumberValue', readonly field: LedgerReportValueField, readonly numberValue: number };

export type TextValueDetails = { readonly __typename: 'LedgerReportResultFieldTextValue', readonly field: LedgerReportValueField, readonly textValue: string };

export type ClientTransactionsIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientTransactionsIntegrationQuery = { readonly __typename: 'Query', readonly clientTransactions: ReadonlyArray<{ readonly __typename: 'ClientTransaction', readonly id: string, readonly type: ClientTransactionType, readonly amount: number, readonly deposits: number, readonly withdrawals: number, readonly investment: number, readonly fees: number, readonly value: number, readonly occurredAt: any, readonly fund: { readonly __typename: 'Fund', readonly id: string, readonly name: string, readonly legalName: string } }> };

export type ClientTransactionsTableRow_ClientTransaction = { readonly __typename: 'ClientTransaction', readonly id: string, readonly type: ClientTransactionType, readonly amount: number, readonly deposits: number, readonly withdrawals: number, readonly investment: number, readonly fees: number, readonly value: number, readonly occurredAt: any, readonly fund: { readonly __typename: 'Fund', readonly id: string, readonly name: string, readonly legalName: string } };

export type TransferNoticeSubmissionFormFundDetail = { readonly __typename: 'Fund', readonly id: string, readonly name: string, readonly legalName: string };

export type TransferNoticeSubmissionIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type TransferNoticeSubmissionIntegrationQuery = { readonly __typename: 'Query', readonly funds: ReadonlyArray<{ readonly __typename: 'Fund', readonly id: string, readonly name: string, readonly legalName: string }> };

export type TransferNoticeSubmissionIntegrationMutationVariables = Exact<{
  input: SubmitTransferNoticeInput;
}>;


export type TransferNoticeSubmissionIntegrationMutation = { readonly __typename: 'Mutation', readonly submitTransferNotice: any | null };

export type TransferNoticesIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type TransferNoticesIntegrationQuery = { readonly __typename: 'Query', readonly transferNotices: ReadonlyArray<{ readonly __typename: 'TransferNotice', readonly id: string, readonly originatingAccountNumber: string, readonly amount: number, readonly processedAt: any | null, readonly effectiveAt: any | null, readonly createdAt: any, readonly fund: { readonly __typename: 'Fund', readonly id: string, readonly name: string, readonly legalName: string } }> };

export type TransferNoticesTableRow_TransferNotice = { readonly __typename: 'TransferNotice', readonly id: string, readonly originatingAccountNumber: string, readonly amount: number, readonly processedAt: any | null, readonly effectiveAt: any | null, readonly createdAt: any, readonly fund: { readonly __typename: 'Fund', readonly id: string, readonly name: string, readonly legalName: string } };

export const ClientInfoDetails = gql`
    fragment ClientInfoDetails on ClientInfo {
  id
  accountNumber
  fullName
  contact {
    streetAddress1
    streetAddress2
    city
    state
    postalCode
    country
  }
}
    `;
export const ClientPerformanceDetails = gql`
    fragment ClientPerformanceDetails on ClientInfo {
  id
  stats(fundId: $fundId, timezone: $timezone) {
    deposits
    withdrawals
    currentValue
    pnlCumulativeRoi
    pnlCumulativeValue
  }
}
    `;
export const DocumentsTableRow_Document = gql`
    fragment DocumentsTableRow_Document on Document {
  id
  type
  fund {
    id
    name
    legalName
  }
  periodStartOn
}
    `;
export const ReportFundSelectionFundDetails = gql`
    fragment ReportFundSelectionFundDetails on Fund {
  id
  name
  legalName
}
    `;
export const NumberValueDetails = gql`
    fragment NumberValueDetails on LedgerReportResultFieldNumberValue {
  field
  numberValue: value
}
    `;
export const TextValueDetails = gql`
    fragment TextValueDetails on LedgerReportResultFieldTextValue {
  field
  textValue: value
}
    `;
export const LedgerReportDetailsFragment = gql`
    fragment LedgerReportDetailsFragment on LedgerReportDetails {
  timeResolution
  startAt
  endAt
  timeZone
  values
  results {
    values {
      ...NumberValueDetails
      ...TextValueDetails
    }
  }
}
    ${NumberValueDetails}
${TextValueDetails}`;
export const ClientTransactionsTableRow_ClientTransaction = gql`
    fragment ClientTransactionsTableRow_ClientTransaction on ClientTransaction {
  id
  type
  fund {
    id
    name
    legalName
  }
  amount
  deposits
  withdrawals
  investment
  fees
  value
  occurredAt
}
    `;
export const TransferNoticeSubmissionFormFundDetail = gql`
    fragment TransferNoticeSubmissionFormFundDetail on Fund {
  id
  name
  legalName
}
    `;
export const TransferNoticesTableRow_TransferNotice = gql`
    fragment TransferNoticesTableRow_TransferNotice on TransferNotice {
  id
  fund {
    id
    name
    legalName
  }
  originatingAccountNumber
  amount
  processedAt
  effectiveAt
  createdAt
}
    `;
export const ClientInfoPanelIntegrationQueryDocument = gql`
    query ClientInfoPanelIntegrationQuery {
  clientInfo {
    id
    ...ClientInfoDetails
  }
}
    ${ClientInfoDetails}`;

/**
 * __useClientInfoPanelIntegrationQuery__
 *
 * To run a query within a React component, call `useClientInfoPanelIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientInfoPanelIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientInfoPanelIntegrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientInfoPanelIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<ClientInfoPanelIntegrationQuery, ClientInfoPanelIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientInfoPanelIntegrationQuery, ClientInfoPanelIntegrationQueryVariables>(ClientInfoPanelIntegrationQueryDocument, options);
      }
export function useClientInfoPanelIntegrationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientInfoPanelIntegrationQuery, ClientInfoPanelIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientInfoPanelIntegrationQuery, ClientInfoPanelIntegrationQueryVariables>(ClientInfoPanelIntegrationQueryDocument, options);
        }
export type ClientInfoPanelIntegrationQueryHookResult = ReturnType<typeof useClientInfoPanelIntegrationQuery>;
export type ClientInfoPanelIntegrationQueryLazyQueryHookResult = ReturnType<typeof useClientInfoPanelIntegrationQueryLazyQuery>;
export type ClientInfoPanelIntegrationQueryQueryResult = Apollo.QueryResult<ClientInfoPanelIntegrationQuery, ClientInfoPanelIntegrationQueryVariables>;
export const ClientPerformanceStatsIntegrationFundsQueryDocument = gql`
    query ClientPerformanceStatsIntegrationFundsQuery {
  funds {
    id
    ...ReportFundSelectionFundDetails
  }
}
    ${ReportFundSelectionFundDetails}`;

/**
 * __useClientPerformanceStatsIntegrationFundsQuery__
 *
 * To run a query within a React component, call `useClientPerformanceStatsIntegrationFundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientPerformanceStatsIntegrationFundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientPerformanceStatsIntegrationFundsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientPerformanceStatsIntegrationFundsQuery(baseOptions?: Apollo.QueryHookOptions<ClientPerformanceStatsIntegrationFundsQuery, ClientPerformanceStatsIntegrationFundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientPerformanceStatsIntegrationFundsQuery, ClientPerformanceStatsIntegrationFundsQueryVariables>(ClientPerformanceStatsIntegrationFundsQueryDocument, options);
      }
export function useClientPerformanceStatsIntegrationFundsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientPerformanceStatsIntegrationFundsQuery, ClientPerformanceStatsIntegrationFundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientPerformanceStatsIntegrationFundsQuery, ClientPerformanceStatsIntegrationFundsQueryVariables>(ClientPerformanceStatsIntegrationFundsQueryDocument, options);
        }
export type ClientPerformanceStatsIntegrationFundsQueryHookResult = ReturnType<typeof useClientPerformanceStatsIntegrationFundsQuery>;
export type ClientPerformanceStatsIntegrationFundsQueryLazyQueryHookResult = ReturnType<typeof useClientPerformanceStatsIntegrationFundsQueryLazyQuery>;
export type ClientPerformanceStatsIntegrationFundsQueryQueryResult = Apollo.QueryResult<ClientPerformanceStatsIntegrationFundsQuery, ClientPerformanceStatsIntegrationFundsQueryVariables>;
export const ClientPerformanceStatsIntegrationQueryDocument = gql`
    query ClientPerformanceStatsIntegrationQuery($fundId: ID, $timezone: TimeZone) {
  clientInfo {
    id
    ...ClientPerformanceDetails
  }
}
    ${ClientPerformanceDetails}`;

/**
 * __useClientPerformanceStatsIntegrationQuery__
 *
 * To run a query within a React component, call `useClientPerformanceStatsIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientPerformanceStatsIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientPerformanceStatsIntegrationQuery({
 *   variables: {
 *      fundId: // value for 'fundId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useClientPerformanceStatsIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<ClientPerformanceStatsIntegrationQuery, ClientPerformanceStatsIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientPerformanceStatsIntegrationQuery, ClientPerformanceStatsIntegrationQueryVariables>(ClientPerformanceStatsIntegrationQueryDocument, options);
      }
export function useClientPerformanceStatsIntegrationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientPerformanceStatsIntegrationQuery, ClientPerformanceStatsIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientPerformanceStatsIntegrationQuery, ClientPerformanceStatsIntegrationQueryVariables>(ClientPerformanceStatsIntegrationQueryDocument, options);
        }
export type ClientPerformanceStatsIntegrationQueryHookResult = ReturnType<typeof useClientPerformanceStatsIntegrationQuery>;
export type ClientPerformanceStatsIntegrationQueryLazyQueryHookResult = ReturnType<typeof useClientPerformanceStatsIntegrationQueryLazyQuery>;
export type ClientPerformanceStatsIntegrationQueryQueryResult = Apollo.QueryResult<ClientPerformanceStatsIntegrationQuery, ClientPerformanceStatsIntegrationQueryVariables>;
export const ContactUsIntegrationQueryDocument = gql`
    query ContactUsIntegrationQuery {
  clientInfo {
    id
    fullName
    contact {
      firstName
      emailAddress
    }
  }
}
    `;

/**
 * __useContactUsIntegrationQuery__
 *
 * To run a query within a React component, call `useContactUsIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactUsIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactUsIntegrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useContactUsIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<ContactUsIntegrationQuery, ContactUsIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactUsIntegrationQuery, ContactUsIntegrationQueryVariables>(ContactUsIntegrationQueryDocument, options);
      }
export function useContactUsIntegrationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactUsIntegrationQuery, ContactUsIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactUsIntegrationQuery, ContactUsIntegrationQueryVariables>(ContactUsIntegrationQueryDocument, options);
        }
export type ContactUsIntegrationQueryHookResult = ReturnType<typeof useContactUsIntegrationQuery>;
export type ContactUsIntegrationQueryLazyQueryHookResult = ReturnType<typeof useContactUsIntegrationQueryLazyQuery>;
export type ContactUsIntegrationQueryQueryResult = Apollo.QueryResult<ContactUsIntegrationQuery, ContactUsIntegrationQueryVariables>;
export const ContactUsIntegrationMutationDocument = gql`
    mutation ContactUsIntegrationMutation($input: SubmitContactUsInput!) {
  submitContactUs(input: $input)
}
    `;
export type ContactUsIntegrationMutationMutationFn = Apollo.MutationFunction<ContactUsIntegrationMutation, ContactUsIntegrationMutationVariables>;

/**
 * __useContactUsIntegrationMutation__
 *
 * To run a mutation, you first call `useContactUsIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsIntegrationMutation, { data, loading, error }] = useContactUsIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactUsIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<ContactUsIntegrationMutation, ContactUsIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactUsIntegrationMutation, ContactUsIntegrationMutationVariables>(ContactUsIntegrationMutationDocument, options);
      }
export type ContactUsIntegrationMutationHookResult = ReturnType<typeof useContactUsIntegrationMutation>;
export type ContactUsIntegrationMutationMutationResult = Apollo.MutationResult<ContactUsIntegrationMutation>;
export type ContactUsIntegrationMutationMutationOptions = Apollo.BaseMutationOptions<ContactUsIntegrationMutation, ContactUsIntegrationMutationVariables>;
export const DocumentsIntegrationQueryDocument = gql`
    query DocumentsIntegrationQuery {
  documents {
    id
    ...DocumentsTableRow_Document
  }
}
    ${DocumentsTableRow_Document}`;

/**
 * __useDocumentsIntegrationQuery__
 *
 * To run a query within a React component, call `useDocumentsIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsIntegrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentsIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<DocumentsIntegrationQuery, DocumentsIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsIntegrationQuery, DocumentsIntegrationQueryVariables>(DocumentsIntegrationQueryDocument, options);
      }
export function useDocumentsIntegrationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsIntegrationQuery, DocumentsIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsIntegrationQuery, DocumentsIntegrationQueryVariables>(DocumentsIntegrationQueryDocument, options);
        }
export type DocumentsIntegrationQueryHookResult = ReturnType<typeof useDocumentsIntegrationQuery>;
export type DocumentsIntegrationQueryLazyQueryHookResult = ReturnType<typeof useDocumentsIntegrationQueryLazyQuery>;
export type DocumentsIntegrationQueryQueryResult = Apollo.QueryResult<DocumentsIntegrationQuery, DocumentsIntegrationQueryVariables>;
export const DocumentsIntegrationGenerateUrlMutationDocument = gql`
    mutation DocumentsIntegrationGenerateUrlMutation($documentId: ID!) {
  generateDocumentUrl(documentId: $documentId)
}
    `;
export type DocumentsIntegrationGenerateUrlMutationMutationFn = Apollo.MutationFunction<DocumentsIntegrationGenerateUrlMutation, DocumentsIntegrationGenerateUrlMutationVariables>;

/**
 * __useDocumentsIntegrationGenerateUrlMutation__
 *
 * To run a mutation, you first call `useDocumentsIntegrationGenerateUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDocumentsIntegrationGenerateUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [documentsIntegrationGenerateUrlMutation, { data, loading, error }] = useDocumentsIntegrationGenerateUrlMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentsIntegrationGenerateUrlMutation(baseOptions?: Apollo.MutationHookOptions<DocumentsIntegrationGenerateUrlMutation, DocumentsIntegrationGenerateUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DocumentsIntegrationGenerateUrlMutation, DocumentsIntegrationGenerateUrlMutationVariables>(DocumentsIntegrationGenerateUrlMutationDocument, options);
      }
export type DocumentsIntegrationGenerateUrlMutationHookResult = ReturnType<typeof useDocumentsIntegrationGenerateUrlMutation>;
export type DocumentsIntegrationGenerateUrlMutationMutationResult = Apollo.MutationResult<DocumentsIntegrationGenerateUrlMutation>;
export type DocumentsIntegrationGenerateUrlMutationMutationOptions = Apollo.BaseMutationOptions<DocumentsIntegrationGenerateUrlMutation, DocumentsIntegrationGenerateUrlMutationVariables>;
export const ClientPerformanceReportIntegrationQueryDocument = gql`
    query ClientPerformanceReportIntegrationQuery($reportInput: ClientPerformanceReportInput!) {
  clientPerformanceReport(input: $reportInput) {
    ...LedgerReportDetailsFragment
  }
}
    ${LedgerReportDetailsFragment}`;

/**
 * __useClientPerformanceReportIntegrationQuery__
 *
 * To run a query within a React component, call `useClientPerformanceReportIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientPerformanceReportIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientPerformanceReportIntegrationQuery({
 *   variables: {
 *      reportInput: // value for 'reportInput'
 *   },
 * });
 */
export function useClientPerformanceReportIntegrationQuery(baseOptions: Apollo.QueryHookOptions<ClientPerformanceReportIntegrationQuery, ClientPerformanceReportIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientPerformanceReportIntegrationQuery, ClientPerformanceReportIntegrationQueryVariables>(ClientPerformanceReportIntegrationQueryDocument, options);
      }
export function useClientPerformanceReportIntegrationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientPerformanceReportIntegrationQuery, ClientPerformanceReportIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientPerformanceReportIntegrationQuery, ClientPerformanceReportIntegrationQueryVariables>(ClientPerformanceReportIntegrationQueryDocument, options);
        }
export type ClientPerformanceReportIntegrationQueryHookResult = ReturnType<typeof useClientPerformanceReportIntegrationQuery>;
export type ClientPerformanceReportIntegrationQueryLazyQueryHookResult = ReturnType<typeof useClientPerformanceReportIntegrationQueryLazyQuery>;
export type ClientPerformanceReportIntegrationQueryQueryResult = Apollo.QueryResult<ClientPerformanceReportIntegrationQuery, ClientPerformanceReportIntegrationQueryVariables>;
export const FundPerformanceReportIntegrationFundsQueryDocument = gql`
    query FundPerformanceReportIntegrationFundsQuery {
  funds {
    id
    ...ReportFundSelectionFundDetails
  }
}
    ${ReportFundSelectionFundDetails}`;

/**
 * __useFundPerformanceReportIntegrationFundsQuery__
 *
 * To run a query within a React component, call `useFundPerformanceReportIntegrationFundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundPerformanceReportIntegrationFundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundPerformanceReportIntegrationFundsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFundPerformanceReportIntegrationFundsQuery(baseOptions?: Apollo.QueryHookOptions<FundPerformanceReportIntegrationFundsQuery, FundPerformanceReportIntegrationFundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FundPerformanceReportIntegrationFundsQuery, FundPerformanceReportIntegrationFundsQueryVariables>(FundPerformanceReportIntegrationFundsQueryDocument, options);
      }
export function useFundPerformanceReportIntegrationFundsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundPerformanceReportIntegrationFundsQuery, FundPerformanceReportIntegrationFundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FundPerformanceReportIntegrationFundsQuery, FundPerformanceReportIntegrationFundsQueryVariables>(FundPerformanceReportIntegrationFundsQueryDocument, options);
        }
export type FundPerformanceReportIntegrationFundsQueryHookResult = ReturnType<typeof useFundPerformanceReportIntegrationFundsQuery>;
export type FundPerformanceReportIntegrationFundsQueryLazyQueryHookResult = ReturnType<typeof useFundPerformanceReportIntegrationFundsQueryLazyQuery>;
export type FundPerformanceReportIntegrationFundsQueryQueryResult = Apollo.QueryResult<FundPerformanceReportIntegrationFundsQuery, FundPerformanceReportIntegrationFundsQueryVariables>;
export const FundPerformanceReportIntegrationFundPerformanceQueryDocument = gql`
    query FundPerformanceReportIntegrationFundPerformanceQuery($fundId: ID!, $reportInput: PerformanceReportInput!) {
  fund(id: $fundId) {
    performanceReport(input: $reportInput) {
      ...LedgerReportDetailsFragment
    }
  }
}
    ${LedgerReportDetailsFragment}`;

/**
 * __useFundPerformanceReportIntegrationFundPerformanceQuery__
 *
 * To run a query within a React component, call `useFundPerformanceReportIntegrationFundPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundPerformanceReportIntegrationFundPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundPerformanceReportIntegrationFundPerformanceQuery({
 *   variables: {
 *      fundId: // value for 'fundId'
 *      reportInput: // value for 'reportInput'
 *   },
 * });
 */
export function useFundPerformanceReportIntegrationFundPerformanceQuery(baseOptions: Apollo.QueryHookOptions<FundPerformanceReportIntegrationFundPerformanceQuery, FundPerformanceReportIntegrationFundPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FundPerformanceReportIntegrationFundPerformanceQuery, FundPerformanceReportIntegrationFundPerformanceQueryVariables>(FundPerformanceReportIntegrationFundPerformanceQueryDocument, options);
      }
export function useFundPerformanceReportIntegrationFundPerformanceQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundPerformanceReportIntegrationFundPerformanceQuery, FundPerformanceReportIntegrationFundPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FundPerformanceReportIntegrationFundPerformanceQuery, FundPerformanceReportIntegrationFundPerformanceQueryVariables>(FundPerformanceReportIntegrationFundPerformanceQueryDocument, options);
        }
export type FundPerformanceReportIntegrationFundPerformanceQueryHookResult = ReturnType<typeof useFundPerformanceReportIntegrationFundPerformanceQuery>;
export type FundPerformanceReportIntegrationFundPerformanceQueryLazyQueryHookResult = ReturnType<typeof useFundPerformanceReportIntegrationFundPerformanceQueryLazyQuery>;
export type FundPerformanceReportIntegrationFundPerformanceQueryQueryResult = Apollo.QueryResult<FundPerformanceReportIntegrationFundPerformanceQuery, FundPerformanceReportIntegrationFundPerformanceQueryVariables>;
export const FundPerformanceReportIntegrationQueryDocument = gql`
    query FundPerformanceReportIntegrationQuery($reportInput: PerformanceReportInput!) {
  fundPerformanceReport(input: $reportInput) {
    ...LedgerReportDetailsFragment
  }
}
    ${LedgerReportDetailsFragment}`;

/**
 * __useFundPerformanceReportIntegrationQuery__
 *
 * To run a query within a React component, call `useFundPerformanceReportIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFundPerformanceReportIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFundPerformanceReportIntegrationQuery({
 *   variables: {
 *      reportInput: // value for 'reportInput'
 *   },
 * });
 */
export function useFundPerformanceReportIntegrationQuery(baseOptions: Apollo.QueryHookOptions<FundPerformanceReportIntegrationQuery, FundPerformanceReportIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FundPerformanceReportIntegrationQuery, FundPerformanceReportIntegrationQueryVariables>(FundPerformanceReportIntegrationQueryDocument, options);
      }
export function useFundPerformanceReportIntegrationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FundPerformanceReportIntegrationQuery, FundPerformanceReportIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FundPerformanceReportIntegrationQuery, FundPerformanceReportIntegrationQueryVariables>(FundPerformanceReportIntegrationQueryDocument, options);
        }
export type FundPerformanceReportIntegrationQueryHookResult = ReturnType<typeof useFundPerformanceReportIntegrationQuery>;
export type FundPerformanceReportIntegrationQueryLazyQueryHookResult = ReturnType<typeof useFundPerformanceReportIntegrationQueryLazyQuery>;
export type FundPerformanceReportIntegrationQueryQueryResult = Apollo.QueryResult<FundPerformanceReportIntegrationQuery, FundPerformanceReportIntegrationQueryVariables>;
export const ClientTransactionsIntegrationQueryDocument = gql`
    query ClientTransactionsIntegrationQuery {
  clientTransactions {
    id
    ...ClientTransactionsTableRow_ClientTransaction
  }
}
    ${ClientTransactionsTableRow_ClientTransaction}`;

/**
 * __useClientTransactionsIntegrationQuery__
 *
 * To run a query within a React component, call `useClientTransactionsIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientTransactionsIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientTransactionsIntegrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientTransactionsIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<ClientTransactionsIntegrationQuery, ClientTransactionsIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientTransactionsIntegrationQuery, ClientTransactionsIntegrationQueryVariables>(ClientTransactionsIntegrationQueryDocument, options);
      }
export function useClientTransactionsIntegrationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientTransactionsIntegrationQuery, ClientTransactionsIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientTransactionsIntegrationQuery, ClientTransactionsIntegrationQueryVariables>(ClientTransactionsIntegrationQueryDocument, options);
        }
export type ClientTransactionsIntegrationQueryHookResult = ReturnType<typeof useClientTransactionsIntegrationQuery>;
export type ClientTransactionsIntegrationQueryLazyQueryHookResult = ReturnType<typeof useClientTransactionsIntegrationQueryLazyQuery>;
export type ClientTransactionsIntegrationQueryQueryResult = Apollo.QueryResult<ClientTransactionsIntegrationQuery, ClientTransactionsIntegrationQueryVariables>;
export const TransferNoticeSubmissionIntegrationQueryDocument = gql`
    query TransferNoticeSubmissionIntegrationQuery {
  funds {
    id
    ...TransferNoticeSubmissionFormFundDetail
  }
}
    ${TransferNoticeSubmissionFormFundDetail}`;

/**
 * __useTransferNoticeSubmissionIntegrationQuery__
 *
 * To run a query within a React component, call `useTransferNoticeSubmissionIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferNoticeSubmissionIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferNoticeSubmissionIntegrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransferNoticeSubmissionIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<TransferNoticeSubmissionIntegrationQuery, TransferNoticeSubmissionIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransferNoticeSubmissionIntegrationQuery, TransferNoticeSubmissionIntegrationQueryVariables>(TransferNoticeSubmissionIntegrationQueryDocument, options);
      }
export function useTransferNoticeSubmissionIntegrationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransferNoticeSubmissionIntegrationQuery, TransferNoticeSubmissionIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransferNoticeSubmissionIntegrationQuery, TransferNoticeSubmissionIntegrationQueryVariables>(TransferNoticeSubmissionIntegrationQueryDocument, options);
        }
export type TransferNoticeSubmissionIntegrationQueryHookResult = ReturnType<typeof useTransferNoticeSubmissionIntegrationQuery>;
export type TransferNoticeSubmissionIntegrationQueryLazyQueryHookResult = ReturnType<typeof useTransferNoticeSubmissionIntegrationQueryLazyQuery>;
export type TransferNoticeSubmissionIntegrationQueryQueryResult = Apollo.QueryResult<TransferNoticeSubmissionIntegrationQuery, TransferNoticeSubmissionIntegrationQueryVariables>;
export const TransferNoticeSubmissionIntegrationMutationDocument = gql`
    mutation TransferNoticeSubmissionIntegrationMutation($input: SubmitTransferNoticeInput!) {
  submitTransferNotice(input: $input)
}
    `;
export type TransferNoticeSubmissionIntegrationMutationMutationFn = Apollo.MutationFunction<TransferNoticeSubmissionIntegrationMutation, TransferNoticeSubmissionIntegrationMutationVariables>;

/**
 * __useTransferNoticeSubmissionIntegrationMutation__
 *
 * To run a mutation, you first call `useTransferNoticeSubmissionIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferNoticeSubmissionIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferNoticeSubmissionIntegrationMutation, { data, loading, error }] = useTransferNoticeSubmissionIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferNoticeSubmissionIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<TransferNoticeSubmissionIntegrationMutation, TransferNoticeSubmissionIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferNoticeSubmissionIntegrationMutation, TransferNoticeSubmissionIntegrationMutationVariables>(TransferNoticeSubmissionIntegrationMutationDocument, options);
      }
export type TransferNoticeSubmissionIntegrationMutationHookResult = ReturnType<typeof useTransferNoticeSubmissionIntegrationMutation>;
export type TransferNoticeSubmissionIntegrationMutationMutationResult = Apollo.MutationResult<TransferNoticeSubmissionIntegrationMutation>;
export type TransferNoticeSubmissionIntegrationMutationMutationOptions = Apollo.BaseMutationOptions<TransferNoticeSubmissionIntegrationMutation, TransferNoticeSubmissionIntegrationMutationVariables>;
export const TransferNoticesIntegrationQueryDocument = gql`
    query TransferNoticesIntegrationQuery {
  transferNotices {
    id
    ...TransferNoticesTableRow_TransferNotice
  }
}
    ${TransferNoticesTableRow_TransferNotice}`;

/**
 * __useTransferNoticesIntegrationQuery__
 *
 * To run a query within a React component, call `useTransferNoticesIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferNoticesIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransferNoticesIntegrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useTransferNoticesIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<TransferNoticesIntegrationQuery, TransferNoticesIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransferNoticesIntegrationQuery, TransferNoticesIntegrationQueryVariables>(TransferNoticesIntegrationQueryDocument, options);
      }
export function useTransferNoticesIntegrationQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransferNoticesIntegrationQuery, TransferNoticesIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransferNoticesIntegrationQuery, TransferNoticesIntegrationQueryVariables>(TransferNoticesIntegrationQueryDocument, options);
        }
export type TransferNoticesIntegrationQueryHookResult = ReturnType<typeof useTransferNoticesIntegrationQuery>;
export type TransferNoticesIntegrationQueryLazyQueryHookResult = ReturnType<typeof useTransferNoticesIntegrationQueryLazyQuery>;
export type TransferNoticesIntegrationQueryQueryResult = Apollo.QueryResult<TransferNoticesIntegrationQuery, TransferNoticesIntegrationQueryVariables>;