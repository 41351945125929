import { alpha, createTheme, Palette, responsiveFontSizes, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  DarkModePreference,
  getUserDarkModePreference,
  setUserDarkModePreference,
} from '@paypr/mui5-common-components/dist/components/style/utils';
import { useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

export const useDarkMode = (): boolean => {
  const themePreferences = useRecoilValue(themePreferencesState);
  const browserPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const darkModePreference = themePreferences.darkModePreference;
  switch (darkModePreference) {
    case DarkModePreference.DARK:
      return true;

    case DarkModePreference.LIGHT:
      return false;

    default:
      return browserPrefersDarkMode;
  }
};

export type DarkModePreferenceHook = [DarkModePreference, (preference: DarkModePreference) => void];

export const useDarkModePreference = (): DarkModePreferenceHook => {
  const [themePreferences, setThemePreferences] = useRecoilState(themePreferencesState);

  return [
    themePreferences.darkModePreference,
    (preference: DarkModePreference) => {
      setThemePreferences((current) => ({ ...current, darkModePreference: preference }));
    },
  ];
};

export interface BreakpointsHook {
  extraSmall: boolean;
  extraSmallUp: boolean;
  smallDown: boolean;
  smallUp: boolean;
  mediumDown: boolean;
  mediumUp: boolean;
  largeDown: boolean;
  largeUp: boolean;
  extraLargeDown: boolean;
  extraLargeUp: boolean;
}

export const useBreakpoints = (): BreakpointsHook => {
  const theme = useTheme();

  const extraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const extraSmallUp = useMediaQuery(theme.breakpoints.up('xs'));
  const smallDown = useMediaQuery(theme.breakpoints.down('sm'));
  const smallUp = useMediaQuery(theme.breakpoints.up('sm'));
  const mediumDown = useMediaQuery(theme.breakpoints.down('md'));
  const mediumUp = useMediaQuery(theme.breakpoints.up('md'));
  const largeDown = useMediaQuery(theme.breakpoints.down('lg'));
  const largeUp = useMediaQuery(theme.breakpoints.up('lg'));
  const extraLargeDown = useMediaQuery(theme.breakpoints.down('xl'));
  const extraLargeUp = useMediaQuery(theme.breakpoints.up('xl'));

  return {
    extraSmall,
    extraSmallUp,
    smallDown,
    smallUp,
    mediumDown,
    mediumUp,
    largeDown,
    largeUp,
    extraLargeDown,
    extraLargeUp,
  };
};

export interface Colors {
  blue: string;
  yellow: string;
  cyan: string;
  purple: string;
  lime: string;
  pink: string;

  green: string;
  red: string;
}

const darkColors: Colors = {
  blue: '#36a2eb',
  yellow: '#ffce56',
  cyan: '#4bc0c0',
  purple: '#9966ff',
  lime: '#87c04b',
  pink: '#ff6384',
  green: '#2a9822',
  red: '#d03535',
};

const lightColors: Colors = {
  blue: '#1e3a8a',
  yellow: '#8c6d00',
  cyan: '#6e8de1',
  purple: '#4d1f7f',
  lime: '#2a9822',
  pink: '#8a1e85',
  green: '#147c0b',
  red: '#930f0f',
};

export const useColors = (): Colors => {
  const darkMode = useDarkMode();
  return darkMode ? darkColors : lightColors;
};

export const useAppTheme = () => {
  const prefersDarkMode = useDarkMode();
  return useMemo(() => createAppTheme(prefersDarkMode), [prefersDarkMode]);
};

interface ThemePreferences {
  darkModePreference: DarkModePreference;
}

const themePreferencesState = atom<ThemePreferences>({
  key: 'themePreferences',
  default: { darkModePreference: getUserDarkModePreference() },
  effects: [({ onSet }) => onSet((newValue) => setUserDarkModePreference(newValue.darkModePreference))],
});

const createAppTheme = (darkMode: boolean) =>
  responsiveFontSizes(
    createTheme({
      palette: {
        mode: darkMode ? 'dark' : 'light',
      },
      components: {
        MuiButton: {
          defaultProps: {
            variant: 'outlined',
          },
        },
        MuiFormControl: {
          defaultProps: {
            margin: 'normal',
            fullWidth: true,
            variant: 'outlined',
          },
        },
        MuiSelect: {
          defaultProps: {
            fullWidth: true,
            variant: 'outlined',
          },
        },
        MuiTextField: {
          defaultProps: {
            margin: 'normal',
            fullWidth: true,
            variant: 'outlined',
          },
        },
        MuiCard: {
          defaultProps: {
            variant: 'outlined',
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: ({ theme }) => ({
              '&.MuiTableRow-hover:hover': {
                backgroundColor: alpha(theme.palette.primary.main, darkMode ? 0.12 : 0.08),
              },
            }),
          },
        },
      },
      typography: (_palette: Palette) => ({}),
    }),
  );
