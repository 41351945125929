import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { TransferNoticesTableRow_TransferNotice } from '../../generated/graphql';
import RefreshIconButton, { RefreshingComponentProps } from '../common/icons/RefreshIconButton';
import TransferNoticesTable, { TransferNoticeClickEventHandler } from './TransferNoticesTable';

export interface TransferNoticesSectionProps extends RefreshingComponentProps {
  transferNotices?: readonly TransferNoticesTableRow_TransferNotice[];
  onClickTransferNotice?: TransferNoticeClickEventHandler;
}

const TransferNoticesSection = ({
  transferNotices,
  loading,
  refreshing,
  onRefresh,
  onClickTransferNotice,
}: TransferNoticesSectionProps) => (
  <Paper>
    <Toolbar>
      <SectionTitle>Transfer Notices</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton loading={loading} refreshing={refreshing} onRefresh={onRefresh} includeRefreshText />
    </Toolbar>

    <TransferNoticesTable transferNotices={transferNotices} onClickTransferNoticeRow={onClickTransferNotice} />
  </Paper>
);
export default TransferNoticesSection;
