import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import Paper from '@mui/material/Paper';
import ContentLoader from '@paypr/mui5-common-components/dist/components/content/ContentLoader';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailInlineTitle from '@paypr/mui5-common-components/dist/components/typography/DetailInlineTitle';
import DetailInlineValue from '@paypr/mui5-common-components/dist/components/typography/DetailInlineValue';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React from 'react';
import { ClientInfoDetails } from '../../generated/graphql';
import RefreshIconButton, { RefreshingComponentProps } from '../common/icons/RefreshIconButton';

export interface ClientInfoPanelProps extends RefreshingComponentProps {
  clientInfo?: ClientInfoDetails;
}

const ClientInfoPanel = ({ clientInfo, loading, refreshing, onRefresh, autoRefresh }: ClientInfoPanelProps) => (
  <Paper
    css={css`
      min-height: 200px;
    `}
  >
    <DetailContainer>
      <DetailField>
        <SubSectionTitle>
          {clientInfo ? clientInfo.fullName : 'Your Info'}
          <RefreshIconButton
            loading={loading}
            refreshing={refreshing}
            onRefresh={onRefresh}
            autoRefresh={autoRefresh}
            hide
          />
        </SubSectionTitle>
      </DetailField>
      {clientInfo ? (
        <>
          <DetailField>
            <DetailValue>{clientInfo.contact.streetAddress1}</DetailValue>
            <DetailValue>{clientInfo.contact.streetAddress2}</DetailValue>
            <DetailValue>
              {clientInfo.contact.city}, {clientInfo.contact.state || clientInfo.contact.country}{' '}
              {clientInfo.contact.postalCode}
            </DetailValue>
          </DetailField>
          <DetailField>
            <DetailInlineTitle>Account #</DetailInlineTitle>
            <DetailInlineValue>{clientInfo.accountNumber}</DetailInlineValue>
          </DetailField>
        </>
      ) : (
        <DetailField>
          <ContentLoader />
        </DetailField>
      )}
    </DetailContainer>
  </Paper>
);
export default ClientInfoPanel;

export const clientInfoDetailsFragment = gql`
  fragment ClientInfoDetails on ClientInfo {
    id
    accountNumber
    fullName
    contact {
      streetAddress1
      streetAddress2
      city
      state
      postalCode
      country
    }
  }
`;
