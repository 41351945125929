import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import React from 'react';
import { RecoilRoot } from 'recoil';
import AppRoot from './AppRoot';
import ToastProvider from './components/common/containers/ToastProvider';
import { ErrorBoundary } from './hooks/errors';
import { apolloClient } from './services/graphql';
import AppThemeProvider from './style/AppThemeProvider';

const App = () => (
  <RecoilRoot>
    <AppThemeProvider>
      <CssBaseline />
      <ToastProvider>
        <ApolloProvider client={apolloClient}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <ErrorBoundary>
              <AppRoot />
            </ErrorBoundary>
          </LocalizationProvider>
        </ApolloProvider>
      </ToastProvider>
    </AppThemeProvider>
  </RecoilRoot>
);
export default App;
