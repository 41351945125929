import * as React from 'react';
import AppPage from '../components/common/page/AppPage';
import FundPerformanceReportIntegration from '../components/reports/FundPerformanceReportIntegration';
import { ReportView } from '../components/reports/PerformanceReport';
import { usePageTitle } from '../hooks/page';

const PerformancePage = () => {
  usePageTitle('Performance');

  return (
    <AppPage>
      <FundPerformanceReportIntegration reportView={ReportView.Chart} />
    </AppPage>
  );
};
export default PerformancePage;
