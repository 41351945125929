import { gql, NetworkStatus } from '@apollo/client';
import { DateTime } from 'luxon';
import React from 'react';
import {
  useClientPerformanceStatsIntegrationFundsQuery,
  useClientPerformanceStatsIntegrationQuery,
} from '../../generated/graphql';
import { reportFundSelectionFundDetailsFragment } from '../reports/ReportFundSelection';
import ClientPerformanceStats, { clientPerformanceDetailsFragment } from './ClientPerformanceStats';

export interface ClientPerformanceStatsIntegrationProps {
  fundId: string | null;
  onChangeFundId: (fundId: string | null) => void;
}

const ClientPerformanceStatsIntegration = ({ fundId, onChangeFundId }: ClientPerformanceStatsIntegrationProps) => {
  const { data: fundsData, loading: fundsLoading } = useClientPerformanceStatsIntegrationFundsQuery();

  const { data, refetch, loading, networkStatus } = useClientPerformanceStatsIntegrationQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      fundId: fundId,
      timezone: DateTime.local().zoneName,
    },
  });

  return (
    <ClientPerformanceStats
      clientPerformanceDetails={data?.clientInfo}
      fundId={fundId}
      funds={fundsData?.funds || []}
      onChangeFundId={onChangeFundId}
      loading={loading || fundsLoading}
      refreshing={networkStatus == NetworkStatus.refetch}
      onRefresh={refetch}
      autoRefresh
    />
  );
};
export default ClientPerformanceStatsIntegration;

gql`
  query ClientPerformanceStatsIntegrationFundsQuery {
    funds {
      id
      ...ReportFundSelectionFundDetails
    }
  }

  query ClientPerformanceStatsIntegrationQuery($fundId: ID, $timezone: TimeZone) {
    clientInfo {
      id
      ...ClientPerformanceDetails
    }
  }

  ${clientPerformanceDetailsFragment}
  ${reportFundSelectionFundDetailsFragment}
`;
