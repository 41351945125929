import AppBarTitle from '@paypr/mui5-common-components/dist/components/typography/AppBarTitle';
import React from 'react';
import { basePageTitle } from '../../../../hooks/page';
import { useUiConfig } from '../../../../hooks/uiConfig';

const PageBarTitle = () => {
  const { isProd, stage } = useUiConfig();

  const title = isProd ? basePageTitle : `${stage}: ${basePageTitle}`;

  return <AppBarTitle>{title}</AppBarTitle>;
};
export default PageBarTitle;
