import { useState } from 'react';
import { useRecoilStateLoadable, useSetRecoilState } from 'recoil';
import { userState } from '../data/user';
import {
  addSignedInAction,
  addSignedOutAction,
  changePassword,
  getCurrentUser,
  login,
  logout,
  UserInfo,
} from '../services/auth';

export interface AuthenticationSetupHook {}

export const useAuthenticationSetup = (): AuthenticationSetupHook => {
  const setUserState = useSetRecoilState(userState);

  const setUser = (user: UserInfo | null) => {
    setUserState({ currentUser: user });
  };

  addSignedInAction((user) => {
    setUser(user);
  });

  addSignedOutAction(async () => {
    setUser(null);
  });

  return {};
};

export interface AuthenticationHook {
  isAuthenticated: boolean;
  user: UserInfo | null;

  loading: boolean;

  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
}

export const useAuthentication = (): AuthenticationHook => {
  const [loadableUserState, setUserState] = useRecoilStateLoadable(userState);
  const [loading, setLoading] = useState<boolean>(false);
  const isAuthenticated = Boolean(loadableUserState.state === 'hasValue' && loadableUserState.contents.currentUser);

  const setUser = (user: UserInfo | null) => {
    setUserState({ currentUser: user });
  };

  const doLogin = async (email: string, password: string) => {
    setLoading(true);
    try {
      await login(email, password);
      setUser(await getCurrentUser());
    } finally {
      setLoading(false);
    }
  };

  const doLogout = async () => {
    setLoading(true);
    try {
      await logout();
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const doChangePassword = async (oldPassword: string, newPassword: string) => {
    setLoading(true);
    try {
      await changePassword(oldPassword, newPassword);
      setUser(await getCurrentUser());
    } finally {
      setLoading(false);
    }
  };

  return {
    isAuthenticated,
    user: loadableUserState.state === 'hasValue' ? loadableUserState.contents.currentUser : null,
    loading: loading || loadableUserState.state === 'loading',
    login: doLogin,
    logout: doLogout,
    changePassword: doChangePassword,
  };
};
