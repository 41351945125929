import React from 'react';
import PageError, { PageErrorProps } from '@paypr/mui5-common-components/dist/components/page/PageError';
import AppPage from './AppPage';

export interface AppPageErrorProps extends PageErrorProps {}

const AppPageError = (props: AppPageErrorProps) => (
  <AppPage>
    <PageError {...props} />
  </AppPage>
);
export default AppPageError;
