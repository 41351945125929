
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "LedgerReportResultFieldNumberValueLedgerReportResultFieldTextValue": [
      "LedgerReportResultFieldNumberValue",
      "LedgerReportResultFieldTextValue"
    ]
  }
};
      export default result;
    