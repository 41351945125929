import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { useClientInfoPanelIntegrationQuery } from '../../generated/graphql';
import ClientInfoPanel, { clientInfoDetailsFragment } from './ClientInfoPanel';

export interface ClientInfoPanelIntegrationProps {}

const ClientInfoPanelIntegration = ({}: ClientInfoPanelIntegrationProps) => {
  const { data, refetch, loading, networkStatus } = useClientInfoPanelIntegrationQuery();

  return (
    <ClientInfoPanel
      clientInfo={data?.clientInfo}
      loading={loading}
      refreshing={networkStatus == NetworkStatus.refetch}
      onRefresh={refetch}
      autoRefresh
    />
  );
};
export default ClientInfoPanelIntegration;

gql`
  query ClientInfoPanelIntegrationQuery {
    clientInfo {
      id
      ...ClientInfoDetails
    }
  }

  ${clientInfoDetailsFragment}
`;
