import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { useAppTheme } from './theme';

export interface AppThemeProviderProps {
  children?: React.ReactNode;
}

const AppThemeProvider = ({ children }: AppThemeProviderProps) => {
  const appTheme = useAppTheme();
  return <ThemeProvider theme={appTheme} children={children} />;
};
export default AppThemeProvider;
