import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { useClientTransactionsIntegrationQuery } from '../../generated/graphql';
import ClientTransactionsSection from './ClientTransactionsSection';
import { ClientTransactionClickEventHandler, clientTransactionsTableRowFragment } from './ClientTransactionsTable';

export interface ClientTransactionsIntegrationProps {
  onClickClientTransaction?: ClientTransactionClickEventHandler;
}

const ClientTransactionsIntegration = ({ onClickClientTransaction }: ClientTransactionsIntegrationProps) => {
  const { data, loading, networkStatus, refetch } = useClientTransactionsIntegrationQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <ClientTransactionsSection
      transactions={data?.clientTransactions}
      onRefresh={refetch}
      loading={loading}
      refreshing={networkStatus === NetworkStatus.refetch}
      onClickClientTransaction={onClickClientTransaction}
    />
  );
};
export default ClientTransactionsIntegration;

gql`
  query ClientTransactionsIntegrationQuery {
    clientTransactions {
      id
      ...ClientTransactionsTableRow_ClientTransaction
    }
  }

  ${clientTransactionsTableRowFragment}
`;
