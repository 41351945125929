import { gql } from '@apollo/client';
import { LedgerReportTimeResolution, LedgerReportValueField } from '../../generated/graphql';
import { DateTime } from 'luxon';

export enum LedgerReportStandardTimeframe {
  AllTime = 'all-time',
  Year = 'year',
  YearToDate = 'year-to-date',
  PreviousYear = 'previous-year',
  Quarter = 'quarter',
  QuarterToDate = 'quarter-to-date',
  PreviousQuarter = 'previous-quarter',
  Month = 'month',
  MonthToDate = 'month-to-date',
  PreviousMonth = 'previous-month',
  Week = 'week',
  Day = 'day',
}

export interface LedgerReportStandardTimeframeInfo {
  timeframe: LedgerReportStandardTimeframe;
  name: string;
  defaultTimeResolution: LedgerReportTimeResolution;
  timeResolutionOptions: LedgerReportTimeResolution[];
}

export const ledgerReportStandardTimeframes = {
  [LedgerReportStandardTimeframe.AllTime]: {
    timeframe: LedgerReportStandardTimeframe.AllTime,
    name: 'All Time',
    timeResolutionOptions: [LedgerReportTimeResolution.Month, LedgerReportTimeResolution.Day],
    defaultTimeResolution: LedgerReportTimeResolution.Month,
  },
  [LedgerReportStandardTimeframe.Year]: {
    timeframe: LedgerReportStandardTimeframe.Year,
    name: '1 Year',
    timeResolutionOptions: [LedgerReportTimeResolution.Month, LedgerReportTimeResolution.Day],
    defaultTimeResolution: LedgerReportTimeResolution.Month,
  },
  [LedgerReportStandardTimeframe.YearToDate]: {
    timeframe: LedgerReportStandardTimeframe.YearToDate,
    name: 'Year to Date',
    timeResolutionOptions: [LedgerReportTimeResolution.Month, LedgerReportTimeResolution.Day],
    defaultTimeResolution: LedgerReportTimeResolution.Month,
  },
  [LedgerReportStandardTimeframe.PreviousYear]: {
    timeframe: LedgerReportStandardTimeframe.PreviousYear,
    name: 'Previous Year',
    timeResolutionOptions: [LedgerReportTimeResolution.Month, LedgerReportTimeResolution.Day],
    defaultTimeResolution: LedgerReportTimeResolution.Month,
  },
  [LedgerReportStandardTimeframe.Quarter]: {
    timeframe: LedgerReportStandardTimeframe.Quarter,
    name: '90 Days',
    timeResolutionOptions: [LedgerReportTimeResolution.Month, LedgerReportTimeResolution.Day],
    defaultTimeResolution: LedgerReportTimeResolution.Day,
  },
  [LedgerReportStandardTimeframe.QuarterToDate]: {
    timeframe: LedgerReportStandardTimeframe.QuarterToDate,
    name: 'Quarter to Date',
    timeResolutionOptions: [LedgerReportTimeResolution.Month, LedgerReportTimeResolution.Day],
    defaultTimeResolution: LedgerReportTimeResolution.Day,
  },
  [LedgerReportStandardTimeframe.PreviousQuarter]: {
    timeframe: LedgerReportStandardTimeframe.PreviousQuarter,
    name: 'Previous 90 Days',
    timeResolutionOptions: [LedgerReportTimeResolution.Month, LedgerReportTimeResolution.Day],
    defaultTimeResolution: LedgerReportTimeResolution.Day,
  },
  [LedgerReportStandardTimeframe.Month]: {
    timeframe: LedgerReportStandardTimeframe.Month,
    name: '30 Days',
    timeResolutionOptions: [LedgerReportTimeResolution.Day],
    defaultTimeResolution: LedgerReportTimeResolution.Day,
  },
  [LedgerReportStandardTimeframe.MonthToDate]: {
    timeframe: LedgerReportStandardTimeframe.MonthToDate,
    name: 'Month to Date',
    timeResolutionOptions: [LedgerReportTimeResolution.Day],
    defaultTimeResolution: LedgerReportTimeResolution.Day,
  },
  [LedgerReportStandardTimeframe.PreviousMonth]: {
    timeframe: LedgerReportStandardTimeframe.PreviousMonth,
    name: 'Previous 30 Days',
    timeResolutionOptions: [LedgerReportTimeResolution.Day],
    defaultTimeResolution: LedgerReportTimeResolution.Day,
  },
  [LedgerReportStandardTimeframe.Week]: {
    timeframe: LedgerReportStandardTimeframe.Week,
    name: '7 Days',
    timeResolutionOptions: [LedgerReportTimeResolution.Day, LedgerReportTimeResolution.Hour],
    defaultTimeResolution: LedgerReportTimeResolution.Hour,
  },
  [LedgerReportStandardTimeframe.Day]: {
    timeframe: LedgerReportStandardTimeframe.Day,
    name: '24 Hours',
    timeResolutionOptions: [LedgerReportTimeResolution.Day, LedgerReportTimeResolution.Hour],
    defaultTimeResolution: LedgerReportTimeResolution.Hour,
  },
};

export interface LedgerReportTimeResolutionInfo {
  resolution: LedgerReportTimeResolution;
  name: string;
}

export const ledgerReportTimeResolutions = {
  [LedgerReportTimeResolution.Year]: {
    resolution: LedgerReportTimeResolution.Year,
    name: 'Year',
  },
  [LedgerReportTimeResolution.Month]: {
    resolution: LedgerReportTimeResolution.Month,
    name: 'Month',
  },
  [LedgerReportTimeResolution.Week]: {
    resolution: LedgerReportTimeResolution.Week,
    name: 'Week',
  },
  [LedgerReportTimeResolution.Day]: {
    resolution: LedgerReportTimeResolution.Day,
    name: 'Day',
  },
  [LedgerReportTimeResolution.Hour]: {
    resolution: LedgerReportTimeResolution.Hour,
    name: 'Hour',
  },
};

export interface ReportValue extends Record<UsedValueFieldsType, number> {
  periodDateTime: DateTime;
  periodTimestamp: number;
}

export interface ReportValueField {
  valueField: LedgerReportValueField;
  name: string;
}

export const reportValueFields = {
  [LedgerReportValueField.PnlCumulativeInvestmentRoi]: {
    valueField: LedgerReportValueField.PnlCumulativeInvestmentRoi,
    name: 'Investment Growth',
  },
  [LedgerReportValueField.EquityMarketPnlCumulativeRoi]: {
    valueField: LedgerReportValueField.EquityMarketPnlCumulativeRoi,
    name: 'S&P500 Index',
  },
};

export type UsedValueFieldsType = keyof typeof reportValueFields;

export const ledgerReportDetailsFragments = gql`
  fragment LedgerReportDetailsFragment on LedgerReportDetails {
    timeResolution
    startAt
    endAt
    timeZone
    values
    results {
      values {
        ...NumberValueDetails
        ...TextValueDetails
      }
    }
  }

  fragment NumberValueDetails on LedgerReportResultFieldNumberValue {
    field
    numberValue: value
  }

  fragment TextValueDetails on LedgerReportResultFieldTextValue {
    field
    textValue: value
  }
`;
