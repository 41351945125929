import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import * as React from 'react';
import { ErrorInfo } from 'react';
import AppPageError from '../components/common/page/AppPageError';
import { limitLength } from '../utils/strings';
import { useUiConfig } from './uiConfig';

export interface ErrorsHook {
  showError: (error: Error, options?: OptionsObject) => ErrorKey;
  clearError: (errorKey?: ErrorKey) => void;
}

export type ErrorKey = SnackbarKey;

const standardOptions: OptionsObject = {
  variant: 'error',
  persist: true,
};

export const useErrors = (defaultOptions?: OptionsObject): ErrorsHook => {
  const [errorKey, setErrorKey] = React.useState<ErrorKey | undefined>(undefined);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return {
    showError: (error: Error, options?: OptionsObject): ErrorKey => {
      const errorMessage = extractErrorMessage(error);
      console.error('Error: ', errorMessage);
      console.error(error);
      const newErrorKey = enqueueSnackbar(limitLength(errorMessage, 200), {
        ...standardOptions,
        ...defaultOptions,
        ...options,
      });
      setErrorKey((prevErrorKey) => prevErrorKey || newErrorKey);
      return newErrorKey;
    },

    clearError: (newErrorKey?: ErrorKey) => {
      const theErrorKey = newErrorKey || errorKey;
      if (!theErrorKey) {
        console.debug('no error key to clear');
        return;
      }

      closeSnackbar(theErrorKey);

      if (!newErrorKey) {
        setErrorKey(undefined);
      }
    },
  };
};

export interface HasErrorHandler {
  onError?: (error: Error) => void;
}

export interface ErrorLike extends Error {
  response?: {
    status: number;
    headers: { [key: string]: string };
    data: {
      error?: string;
    };
  };
}

export const extractErrorMessage = (error: ErrorLike): string => {
  const responseError = error?.response?.data?.error;
  if (responseError) {
    return `${error.message}. ${responseError}`;
  }

  return error.message;
};

export interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const { showError } = useErrors();
  const { isProd } = useUiConfig();

  const handleError = (error: Error, errorInfo: ErrorInfo) => {
    showError(error);
    console.info('Error Info:', errorInfo);
  };

  return <ErrorBoundaryInternal children={children} onError={handleError} displayErrorDetails={!isProd} />;
};

interface ErrorBoundaryInternalProps {
  children: React.ReactNode;
  displayErrorDetails?: boolean;
  onError: (error: Error, errorInfo: ErrorInfo) => void;
}

class ErrorBoundaryInternal extends React.Component<ErrorBoundaryInternalProps> {
  state: {
    hasError: boolean;
    displayErrorDetails?: boolean;
    error?: Error;
  };

  constructor(props: ErrorBoundaryInternalProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.props.onError(error, errorInfo);
    this.setState((props) => ({ ...props, error }));
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <AppPageError error={this.props.displayErrorDetails && this.state.error ? this.state.error : undefined} />;
    }

    return this.props.children;
  }
}
