import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DefaultTextField from '@paypr/mui5-common-components/dist/components/forms/DefaultTextField';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailTitle from '@paypr/mui5-common-components/dist/components/typography/DetailTitle';
import DetailValue from '@paypr/mui5-common-components/dist/components/typography/DetailValue';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import * as React from 'react';
import { FormEvent } from 'react';
import { useFormFields } from '../../hooks/forms';
import { isNotBlank } from '../../utils/strings';

interface ChangePasswordFormProps {
  email: string;
  loading?: boolean;
  disabled?: boolean;
  onUpdatePassword: (oldPassword: string, newPassword: string) => void;
  onCancel?: () => void;
}

interface ChangePasswordFormFields {
  oldPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

const ChangePasswordForm = ({ email, loading, disabled, onUpdatePassword, onCancel }: ChangePasswordFormProps) => {
  const { fields, handleFieldChange, resetFields } = useFormFields<ChangePasswordFormFields>({
    oldPassword: '',
    newPassword: '',
    repeatNewPassword: '',
  });

  const validateForm = () =>
    isNotBlank(fields.oldPassword) &&
    isNotBlank(fields.newPassword) &&
    fields.newPassword !== fields.oldPassword &&
    fields.newPassword === fields.repeatNewPassword;

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    onUpdatePassword(fields.oldPassword, fields.newPassword);

    resetFields();
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <SubSectionTitle>Change Password</SubSectionTitle>

        <DetailField>
          <DetailTitle>Email</DetailTitle>
          <DetailValue>{email}</DetailValue>
        </DetailField>

        <DefaultTextField
          autoFocus
          name="oldPassword"
          type="password"
          label="Current Password"
          autoComplete="current-password"
          value={fields.oldPassword}
          required
          onChange={handleFieldChange}
        />

        <DefaultTextField
          name="newPassword"
          type="password"
          label="New Password"
          autoComplete="new-password"
          value={fields.newPassword}
          required
          onChange={handleFieldChange}
        />

        <DefaultTextField
          name="repeatNewPassword"
          type="password"
          label="Repeat New Password"
          autoComplete="new-password"
          value={fields.repeatNewPassword}
          required
          onChange={handleFieldChange}
        />
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <LoaderButton type="button" loading={loading} disabled={disabled} onClick={onCancel}>
            Cancel
          </LoaderButton>
        )}
        <LoaderButton type="submit" loading={loading} disabled={disabled || !validateForm()} color="primary">
          Change Password
        </LoaderButton>
      </DialogActions>
    </form>
  );
};
export default ChangePasswordForm;
