import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import HorizontalAutoSpacer from '@paypr/mui5-common-components/dist/components/style/HorizontalAutoSpacer';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import { DocumentsTableRow_Document } from '../../generated/graphql';
import RefreshIconButton, { RefreshingComponentProps } from '../common/icons/RefreshIconButton';
import DocumentsTable, { DocumentClickEventHandler, DocumentDownloadEventHandler } from './DocumentsTable';

export interface DocumentsSectionProps extends RefreshingComponentProps {
  documents?: readonly DocumentsTableRow_Document[];
  hideClient?: boolean;
  hideFund?: boolean;
  onClickDocument?: DocumentClickEventHandler;
  onDownloadDocument?: DocumentDownloadEventHandler;
}

const DocumentsSection = ({
  documents,
  hideFund,
  onClickDocument,
  onDownloadDocument,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: DocumentsSectionProps) => (
  <Paper>
    <Toolbar>
      <SectionTitle>Documents</SectionTitle>
      <HorizontalAutoSpacer />
      <RefreshIconButton
        refreshing={refreshing}
        loading={loading}
        onRefresh={onRefresh}
        autoRefresh={autoRefresh}
        includeRefreshText
      />
    </Toolbar>

    <DocumentsTable
      documents={documents}
      hideFund={hideFund}
      onClickDocumentRow={onClickDocument}
      onDownloadDocument={onDownloadDocument}
    />
  </Paper>
);
export default DocumentsSection;
