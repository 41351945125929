import { isNumber, isPositiveNumber, isPositiveNumberOrZero } from './numbers';

export const isBlank = (str?: string | null): boolean => !str || str.length === 0 || str.trim().length === 0;
export const isNotBlank = (str?: string | null): boolean => !isBlank(str);

export const trimToNull = (str?: string | null): string | null => (str ? str.trim() : null);

export const limitLength = (str: string | null | undefined, len: number): string =>
  str ? (str.length > len ? str.substring(0, len) + '...' : str) : '';

export const isTextNumber = (str?: string | null): boolean =>
  str !== undefined && str !== null && !isBlank(str) && isNumber(parseInt(str, 10));

export const isTextPositiveInt = (str?: string | null): boolean =>
  str !== undefined && str !== null && !isBlank(str) && isPositiveNumber(parseInt(str, 10));

export const isTextPositiveFloat = (str?: string | null): boolean =>
  str !== undefined && str !== null && !isBlank(str) && isPositiveNumber(parseFloat(str));

export const isTextPositiveIntOrZero = (str?: string | null): boolean =>
  str !== undefined && str !== null && !isBlank(str) && isPositiveNumberOrZero(parseInt(str, 10));

export const isTextPositiveFloatOrZero = (str?: string | null): boolean =>
  str !== undefined && str !== null && !isBlank(str) && isPositiveNumberOrZero(parseFloat(str));

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const snakeOrKebabToDisplay = (str: string): string => {
  const parts = str.split('-').flatMap((part) => part.split('_'));
  return parts.map(capitalize).join(' ');
};
