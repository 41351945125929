import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useContactUsIntegrationMutation, useContactUsIntegrationQuery } from '../../generated/graphql';
import { useErrors } from '../../hooks/errors';
import ContactUsForm, { ContactUsFormFields } from './ContactUsForm';

export interface ContactUsIntegrationProps {}

const ContactUsIntegration = ({}: ContactUsIntegrationProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showError, clearError } = useErrors();

  const { data } = useContactUsIntegrationQuery();
  const [submitContactUs, { loading }] = useContactUsIntegrationMutation();

  const handleSubmitContactUs = async (contactUsFields: ContactUsFormFields) => {
    clearError();
    try {
      await submitContactUs({
        variables: {
          input: contactUsFields,
        },
      });

      enqueueSnackbar('Message sent successfully.', { variant: 'success' });
    } catch (e) {
      showError(e);
    }
  };

  return (
    <ContactUsForm
      defaultName={data?.clientInfo?.contact.firstName}
      defaultEmailAddress={data?.clientInfo?.contact?.emailAddress}
      onSubmitContactUs={handleSubmitContactUs}
      loading={loading}
    />
  );
};
export default ContactUsIntegration;

gql`
  query ContactUsIntegrationQuery {
    clientInfo {
      id
      fullName
      contact {
        firstName
        emailAddress
      }
    }
  }

  mutation ContactUsIntegrationMutation($input: SubmitContactUsInput!) {
    submitContactUs(input: $input)
  }
`;
