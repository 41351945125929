import Button from '@mui/material/Button';
import { SnackbarKey, SnackbarProvider, SnackbarProviderProps } from 'notistack';
import * as React from 'react';
import { PropsWithChildren } from 'react';

interface ToastProviderProps extends SnackbarProviderProps {}

const ToastProvider = ({ children, ...rest }: PropsWithChildren<ToastProviderProps>) => {
  const snackbarRef: React.RefObject<SnackbarProvider> = React.createRef();
  const handleDismiss = (key: SnackbarKey) => {
    snackbarRef.current?.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      {...rest}
      ref={snackbarRef}
      children={children}
      action={(key: SnackbarKey) => (
        <Button size="small" color="inherit" onClick={() => handleDismiss(key)}>
          Dismiss
        </Button>
      )}
    />
  );
};

export default ToastProvider;
