import memoize from 'fast-memoize';

export interface UiConfig {
  stage: string;

  api: {
    region: string;
    baseUrl: string;
  };

  auth: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
    identityPoolId: string;
  };
}

export const getUiConfig = memoize(async (): Promise<UiConfig> => {
  const response = await fetch('/api/ui-config');
  if (!response.ok) {
    throw new Error(`Error getting UI config: ${response.statusText} (${response.status})`);
  }
  return response.json();
});

export const isStageProd = memoize(async (): Promise<boolean> => {
  const { stage } = await getUiConfig();
  return stage?.toLowerCase() === 'prod';
});
