import * as React from 'react';
import AppPage from '../components/common/page/AppPage';
import ClientTransactionsIntegration from '../components/transactions/ClientTransactionsIntegration';
import TransferInfoPanel from '../components/transfers/TransferInfoPanel';
import TransferNoticesIntegration from '../components/transfers/TransferNoticesIntegration';
import TransferNoticeSubmissionIntegration from '../components/transfers/TransferNoticeSubmissionIntegration';
import { usePageTitle } from '../hooks/page';

const TransferPage = () => {
  usePageTitle('Transfer');

  return (
    <AppPage>
      <TransferInfoPanel />
      <br />
      <TransferNoticeSubmissionIntegration />
      <br />
      <TransferNoticesIntegration />
      <br />
      <ClientTransactionsIntegration />
    </AppPage>
  );
};
export default TransferPage;
