import { gql, NetworkStatus } from '@apollo/client';
import React from 'react';
import { useTransferNoticesIntegrationQuery } from '../../generated/graphql';
import TransferNoticesSection from './TransferNoticesSection';
import { TransferNoticeClickEventHandler, transferNoticesTableRowFragment } from './TransferNoticesTable';

export interface TransferNoticesIntegrationProps {
  onClickTransferNotice?: TransferNoticeClickEventHandler;
}

const TransferNoticesIntegration = ({ onClickTransferNotice }: TransferNoticesIntegrationProps) => {
  const { data, loading, networkStatus, refetch } = useTransferNoticesIntegrationQuery({
    fetchPolicy: 'cache-and-network',
  });

  return (
    <TransferNoticesSection
      transferNotices={data?.transferNotices}
      onRefresh={refetch}
      loading={loading}
      refreshing={networkStatus === NetworkStatus.refetch}
      onClickTransferNotice={onClickTransferNotice}
    />
  );
};
export default TransferNoticesIntegration;

gql`
  query TransferNoticesIntegrationQuery {
    transferNotices {
      id
      ...TransferNoticesTableRow_TransferNotice
    }
  }

  ${transferNoticesTableRowFragment}
`;
