import { gql } from '@apollo/client';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import AlternatingTableRow from '@paypr/mui5-common-components/dist/components/tables/AlternatingTableRow';
import EmptyTableRow from '@paypr/mui5-common-components/dist/components/tables/EmptyTableRow';
import HeightLimitedTableContainer from '@paypr/mui5-common-components/dist/components/tables/HeightLimitedTableContainer';
import {
  SimpleSortingTableHeadCell,
  SimpleSortingTableHeadProps,
} from '@paypr/mui5-common-components/dist/components/tables/SortingTableHead';
import { getComparator, stableSort, useSorting } from '@paypr/mui5-common-components/dist/components/tables/sortUtils';
import { DateTime } from 'luxon';
import * as React from 'react';
import { DocumentsTableRow_Document, DocumentType } from '../../generated/graphql';
import { snakeOrKebabToDisplay } from '../../utils/strings';
import SimpleSortingTableHead from '../common/tables/SortingTableHead';

interface DocumentsTableProps {
  documents?: readonly DocumentsTableRow_Document[];
  hideFund?: boolean;
  onClickDocumentRow?: DocumentClickEventHandler;
  onDownloadDocument?: DocumentDownloadEventHandler;
}

export type DocumentClickEventHandler = (documentId: string, newTab: boolean) => void;
export type DocumentDownloadEventHandler = (documentId: string) => void;

interface DocumentsTableRowData extends DocumentsTableRow_Document {
  fundName: string;
  periodSortKey: string;
}

const DocumentsTable = ({ hideFund, documents, onClickDocumentRow, onDownloadDocument }: DocumentsTableProps) => {
  const { order, orderBy, handleRequestSort } = useSorting<DocumentsTableRowData>('periodSortKey', 'desc');

  const showFund = !hideFund && (new Set(documents?.map(({ fund }) => fund.id)) ?? new Set()).size > 1;

  const createClickHandler = (documentId: string) => {
    if (!onClickDocumentRow) {
      return undefined;
    }
    return (event) => onClickDocumentRow(documentId, event.ctrlKey || event.metaKey);
  };

  const documentData = documents?.map((document) => ({
    ...document,
    fundName: document.fund.name,
    periodSortKey: buildDocumentPeriodSortKey(document.type, document.periodStartOn),
  }));

  return (
    <HeightLimitedTableContainer>
      <Table stickyHeader>
        <DocumentsTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} showFund={showFund} />
        <TableBody>
          {documentData && documentData.length > 0 ? (
            stableSort(documentData, getComparator(order, orderBy)).map((document) => (
              <DocumentTableRow
                key={document.id}
                document={document}
                onRowClick={createClickHandler(document.id)}
                onDownload={onDownloadDocument}
                showFund={showFund}
              />
            ))
          ) : (
            <EmptyTableRow columnCount={columnHeadings.length}>
              {documents ? 'No documents are available.' : 'Loading documents...'}
            </EmptyTableRow>
          )}
        </TableBody>
      </Table>
    </HeightLimitedTableContainer>
  );
};
export default DocumentsTable;

interface DocumentsTableHeadProps extends Omit<SimpleSortingTableHeadProps<DocumentsTableRowData>, 'headings'> {
  showFund: boolean;
}

const columnHeadings: SimpleSortingTableHeadCell<DocumentsTableRowData>[] = [
  { key: 'fundName', label: 'Fund', align: 'left' },
  { key: 'type', label: 'Type', align: 'left' },
  { key: 'periodSortKey', label: 'Period', align: 'right' },
  { key: 'id', label: 'Download', align: 'center' },
];

const columnHeadingsWithoutFund = columnHeadings.filter(({ key }) => key !== 'fundName');

const DocumentsTableHead = ({ showFund, ...props }: DocumentsTableHeadProps) => {
  const buildColumnHeadings = () => {
    if (!showFund) {
      return columnHeadingsWithoutFund;
    }
    return columnHeadings;
  };

  return <SimpleSortingTableHead {...props} headings={buildColumnHeadings()} />;
};

type DocumentTableRowProps = {
  document: DocumentsTableRowData;
  showFund: boolean;
  onRowClick?: React.MouseEventHandler;
  onDownload?: DocumentDownloadEventHandler;
};

export const DocumentTableRow = ({ document, showFund, onRowClick, onDownload }: DocumentTableRowProps) => (
  <AlternatingTableRow hover={Boolean(onRowClick)} onClick={onRowClick}>
    {showFund && <TableCell title={document.fund.legalName}>{document.fundName}</TableCell>}
    <TableCell>{buildDocumentTypeDisplay(document.type)}</TableCell>
    <TableCell align="right">{buildDocumentPeriodDisplay(document.type, document.periodStartOn)}</TableCell>
    <TableCell align="center">
      {onDownload && (
        <Link href="#" onClick={() => onDownload(document.id)}>
          Download
        </Link>
      )}
    </TableCell>
  </AlternatingTableRow>
);

const buildDocumentPeriodSortKey = (type: DocumentType, periodStartOn: string) => {
  switch (type) {
    case DocumentType.K1:
      return DateTime.fromISO(periodStartOn).toFormat("yyyy-'99'");

    case DocumentType.Statement:
      return DateTime.fromISO(periodStartOn).toFormat('yyyy-MM');

    default:
      console.warn(`Unknown client document type: ${type}`);
      return periodStartOn;
  }
};

export const buildDocumentPeriodDisplay = (type: DocumentType, periodStartOn: string) => {
  switch (type) {
    case DocumentType.K1:
      return DateTime.fromISO(periodStartOn).toFormat('yyyy');

    case DocumentType.Statement:
      return DateTime.fromISO(periodStartOn).toFormat('MMM yyyy');

    default:
      console.warn(`Unknown client document type: ${type}`);
      return periodStartOn;
  }
};

export const buildDocumentTypeDisplay = (type: DocumentType) => {
  switch (type) {
    case DocumentType.K1:
      return 'K-1';

    case DocumentType.Statement:
      return 'Statement';

    default:
      console.warn(`Unknown document type: ${type}`);
      return snakeOrKebabToDisplay(type);
  }
};

export const documentsTableRowFragment = gql`
  fragment DocumentsTableRow_Document on Document {
    id
    type
    fund {
      id
      name
      legalName
    }
    periodStartOn
  }
`;
