import { gql, NetworkStatus } from '@apollo/client';
import React, { useEffect } from 'react';
import {
  LedgerReportTimeResolution,
  useFundPerformanceReportIntegrationFundPerformanceQuery,
  useFundPerformanceReportIntegrationFundsQuery,
} from '../../generated/graphql';
import PerformanceReport, { ReportView } from './PerformanceReport';
import ReportFundSelection, { reportFundSelectionFundDetailsFragment } from './ReportFundSelection';
import { buildPerformanceReportInput } from './reportHelpers';
import {
  ledgerReportDetailsFragments,
  LedgerReportStandardTimeframe,
  ledgerReportStandardTimeframes,
} from './reportModel';

export interface FundPerformanceReportIntegrationProps {
  reportView: ReportView;
}

const FundPerformanceReportIntegration = ({ reportView }: FundPerformanceReportIntegrationProps) => {
  const { data: fundsData, loading: fundsLoading } = useFundPerformanceReportIntegrationFundsQuery();

  const [fundId, setFundId] = React.useState<string>();
  const [reportTimeframe, setReportTimeframe] = React.useState(LedgerReportStandardTimeframe.Year);
  const [reportTimeResolution, setReportTimeResolution] = React.useState(
    ledgerReportStandardTimeframes[reportTimeframe].defaultTimeResolution,
  );

  useEffect(() => {
    if (fundsData?.funds && !fundId) {
      setFundId(fundsData.funds[0].id);
    }
  }, [fundsData?.funds]);

  const {
    data: performanceData,
    loading: performanceLoading,
    networkStatus: performanceNetworkStatus,
    refetch,
  } = useFundPerformanceReportIntegrationFundPerformanceQuery({
    fetchPolicy: 'cache-and-network',
    skip: !fundId,
    variables: {
      fundId: fundId || '',
      reportInput: buildPerformanceReportInput(reportTimeframe, reportTimeResolution),
    },
  });

  const handleSelectTimeframe = (timeframe: LedgerReportStandardTimeframe, resolution: LedgerReportTimeResolution) => {
    setReportTimeframe(timeframe);
    setReportTimeResolution(resolution);
  };

  return (
    <PerformanceReport
      title="Fund Performance"
      description="The performance of the fund across all investors. This is not your individual investment performance"
      reportView={reportView}
      reportTimeframe={reportTimeframe}
      reportTimeResolution={reportTimeResolution}
      reportDetails={performanceData?.fund?.performanceReport || null}
      onSelectTimeframe={handleSelectTimeframe}
      loading={fundsLoading || performanceLoading}
      refreshing={performanceNetworkStatus === NetworkStatus.refetch}
      onRefresh={() => refetch()}
      autoRefresh
      pickers={
        fundsData?.funds && fundId ? (
          <ReportFundSelection funds={fundsData?.funds} selected={fundId} onSelect={setFundId} />
        ) : null
      }
    />
  );
};
export default FundPerformanceReportIntegration;

gql`
  query FundPerformanceReportIntegrationFundsQuery {
    funds {
      id
      ...ReportFundSelectionFundDetails
    }
  }

  query FundPerformanceReportIntegrationFundPerformanceQuery($fundId: ID!, $reportInput: PerformanceReportInput!) {
    fund(id: $fundId) {
      performanceReport(input: $reportInput) {
        ...LedgerReportDetailsFragment
      }
    }
  }

  query FundPerformanceReportIntegrationQuery($reportInput: PerformanceReportInput!) {
    fundPerformanceReport(input: $reportInput) {
      ...LedgerReportDetailsFragment
    }
  }

  ${reportFundSelectionFundDetailsFragment}
  ${ledgerReportDetailsFragments}
`;
