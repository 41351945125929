import PageLoader from '@paypr/mui5-common-components/dist/components/page/PageLoader';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthentication } from '../../../hooks/auth';
import { buildLoginPathWithRedirect } from './paths';

interface AuthenticatedProps {
  children?: React.ReactNode;
}

const Authenticated = ({ children }: AuthenticatedProps) => {
  const { pathname, search } = useLocation();
  const { isAuthenticated, loading } = useAuthentication();

  if (loading) {
    return <PageLoader />;
  }

  if (!isAuthenticated) {
    console.log('not authenticated. redirecting to login');
    return <Navigate to={buildLoginPathWithRedirect(pathname, search)} />;
  }

  return <>{children}</>;
};
export default Authenticated;
