import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// redirect
export const redirectParam = 'redirect';
export interface RedirectQueryParams {
  redirect: string;
}

export const homePath = '/';
export const performancePath = '/performance';
export const documentsPath = '/documents';
export const transferPath = '/transfer';
export const supportPath = '/support';

// user actions

// login
export const loginPath = '/login';
export const buildLoginPathWithRedirect = (redirectPath: string, redirectQuery: string) =>
  buildPathWithRedirect(loginPath, redirectPath, redirectQuery);

// change password
const userPath = '/account';
export const changePasswordPath = `${userPath}/change-password`;

// common
const buildPathWithRedirect = (path: string, redirectPath: string, redirectQuery: string) =>
  `${path}?${redirectParam}=${redirectPath}${redirectQuery}`;

export interface QueryHook<T> {
  params: QueryParams<T>;
  updateParam: <K extends keyof T & string>(key: K, value: string) => void;
  updateParams: (params: T) => void;
}

export interface QueryParams<T> {
  get: <K extends keyof T & string>(key: K) => string | null;
}

export const useQuery = <T extends object>(): QueryHook<T> => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const gotoSearch = () => {
    navigate({ search: params.toString() });
  };

  const updateSearch = (key: string, value: string | null) => {
    if (value !== null) {
      params.set(key, value);
    } else {
      params.delete(key);
    }
  };

  const updateParams = (params: T) => {
    Object.entries(params).forEach(([key, value]) => {
      updateSearch(key, value);
    });
    gotoSearch();
  };

  const updateParam = <K extends keyof T & string>(key: K, value: string | null) => {
    updateSearch(key, value);
    gotoSearch();
  };

  return { params, updateParam, updateParams };
};

export interface PathsHook {
  navigateTo: (path: string, newTab?: boolean) => void;
}

export const usePaths = (): PathsHook => {
  const navigate = useNavigate();

  const navigateTo = (path: string, newTab: boolean = false) => {
    if (newTab) {
      window.open(path, '_blank');
      return;
    }

    navigate(path);
  };

  return {
    navigateTo,
  };
};
