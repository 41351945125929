import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ContentLoader from '@paypr/mui5-common-components/dist/components/content/ContentLoader';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import SubSectionTitle from '@paypr/mui5-common-components/dist/components/typography/SubSectionTitle';
import React from 'react';
import { ClientPerformanceDetails, ReportFundSelectionFundDetails } from '../../generated/graphql';
import RefreshIconButton, { RefreshingComponentProps } from '../common/icons/RefreshIconButton';
import PercentFormat from '../common/typography/PercentFormat';
import UsdFormat from '../common/typography/UsdFormat';

export interface ClientPerformanceStatsProps extends RefreshingComponentProps {
  fundId: string | null;
  funds: readonly ReportFundSelectionFundDetails[];
  onChangeFundId: (fundId: string | null) => void;
  clientPerformanceDetails?: ClientPerformanceDetails;
}

const ALL_FUNDS_ID = 'all';

const ClientPerformanceStats = ({
  clientPerformanceDetails,
  fundId,
  funds,
  onChangeFundId,
  loading,
  refreshing,
  onRefresh,
  autoRefresh,
}: ClientPerformanceStatsProps) => {
  const theme = useTheme();

  const gainsOrLosses = (stat: number) => (stat >= 0 ? 'Gains' : 'Losses');

  const handleSelectFund = (event) => {
    const value = event.target.value;
    if (value === ALL_FUNDS_ID) {
      onChangeFundId(null);
      return;
    }
    onChangeFundId(value);
  };

  return (
    <Paper
      css={css`
        min-height: 200px;
      `}
    >
      <DetailContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>
                <SubSectionTitle>
                  <Box
                    css={css`
                      display: inline-block;
                    `}
                  >
                    Your Capital Account Summary
                  </Box>{' '}
                  {funds && funds.length > 1 && (
                    <FormControl
                      size="small"
                      margin="dense"
                      sx={{ minWidth: theme.spacing(10), maxWidth: theme.spacing(15) }}
                      css={css`
                        vertical-align: middle;
                        display: inline-block;
                      `}
                    >
                      <Select value={fundId || ALL_FUNDS_ID} onChange={handleSelectFund}>
                        <MenuItem value={ALL_FUNDS_ID}>All</MenuItem>
                        {funds.map((fund) => (
                          <MenuItem key={fund.id} value={fund.id} title={fund.legalName}>
                            {fund.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </SubSectionTitle>
              </TableCell>
              <TableCell align="right">
                <RefreshIconButton
                  loading={loading}
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                  autoRefresh={autoRefresh}
                  hideAutoRefresh
                />
              </TableCell>
            </TableRow>
          </TableHead>
          {clientPerformanceDetails ? (
            <TableBody>
              <TableRow>
                <TableCell variant="head">Total Deposits</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
                <TableCell align="right">
                  <UsdFormat amount={clientPerformanceDetails.stats.deposits} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Total Withdrawals</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
                <TableCell align="right">
                  <UsdFormat amount={clientPerformanceDetails.stats.withdrawals} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">
                  Investment {gainsOrLosses(clientPerformanceDetails.stats.pnlCumulativeValue)}
                </TableCell>
                <TableCell align="right">
                  <PercentFormat amount={clientPerformanceDetails.stats.pnlCumulativeRoi} />
                </TableCell>
                <TableCell align="right">
                  <UsdFormat amount={clientPerformanceDetails.stats.pnlCumulativeValue} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Current Balance</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
                <TableCell align="right">
                  <UsdFormat amount={clientPerformanceDetails.stats.currentValue} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3}>
                  <ContentLoader />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </DetailContainer>
    </Paper>
  );
};
export default ClientPerformanceStats;

export const clientPerformanceDetailsFragment = gql`
  fragment ClientPerformanceDetails on ClientInfo {
    id
    stats(fundId: $fundId, timezone: $timezone) {
      deposits
      withdrawals
      currentValue
      pnlCumulativeRoi
      pnlCumulativeValue
    }
  }
`;
