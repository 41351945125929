import { atom } from 'recoil';

export interface UiState {
  stage: Stage;
}

export enum Stage {
  PROD = 'PROD',
  TEST = 'TEST',
  LOCAL = 'LOCAL',
}

export const uiState = atom<UiState>({
  key: 'ui',
  default: { stage: Stage.PROD },
});
