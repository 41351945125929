import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import SectionTitle from '@paypr/mui5-common-components/dist/components/typography/SectionTitle';
import React from 'react';
import ExpandIcon from '../common/icons/ExpandIcon';
import RedemptionRequestIntegration from './RedemptionRequestIntegration';

export interface RedemptionRequestSectionProps {}

const RedemptionRequestSection = ({}: RedemptionRequestSectionProps) => (
  <Paper>
    <Accordion defaultExpanded={false}>
      <AccordionSummary expandIcon={<ExpandIcon />}>
        <Toolbar>
          <SectionTitle>Redemption Request Form</SectionTitle>
        </Toolbar>
      </AccordionSummary>
      <AccordionDetails>
        <RedemptionRequestIntegration />
      </AccordionDetails>
    </Accordion>
  </Paper>
);
export default RedemptionRequestSection;
