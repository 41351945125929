import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import ClientInfoPanelIntegration from '../components/client/ClientInfoPanelIntegration';
import ClientPerformanceStatsIntegration from '../components/client/ClientPerformanceStatsIntegration';
import AppPage from '../components/common/page/AppPage';
import ClientPerformanceReportIntegration from '../components/reports/ClientPerformanceReportIntegration';
import { ReportView } from '../components/reports/PerformanceReport';
import { usePageTitle } from '../hooks/page';
import { useBreakpoints } from '../style/theme';

const HomePage = () => {
  const theme = useTheme();
  const { largeUp } = useBreakpoints();

  usePageTitle();

  const [performanceFundId, setPerformanceFundId] = React.useState<string | null>(null);

  return (
    <AppPage>
      <Box
        css={css`
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={6} lg={4}>
            <ClientInfoPanelIntegration />
          </Grid>
          {largeUp && <Grid item lg={4} />}
          <Grid item xs={12} sm={6} lg={4}>
            <ClientPerformanceStatsIntegration fundId={performanceFundId} onChangeFundId={setPerformanceFundId} />
          </Grid>
        </Grid>
      </Box>
      <ClientPerformanceReportIntegration fundId={performanceFundId} reportView={ReportView.Chart} />
    </AppPage>
  );
};
export default HomePage;
