import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { BadRoute } from '../../../pages/BadRoute';
import DocumentsPage from '../../../pages/DocumentsPage';
import HomePage from '../../../pages/HomePage';
import PerformancePage from '../../../pages/PerformancePage';
import SupportPage from '../../../pages/SupportPage';
import TransferPage from '../../../pages/TransferPage';
import ChangePasswordPage from '../../../pages/user/ChangePasswordPage';
import LoginPage from '../../../pages/user/LoginPage';
import Authenticated from './Authenticated';
import {
  changePasswordPath,
  documentsPath,
  homePath,
  loginPath,
  performancePath,
  supportPath,
  transferPath,
} from './paths';
import Unauthenticated from './Unauthenticated';

const AppRoutes = () => (
  <Routes>
    {/* Home */}
    <Route
      path={homePath}
      element={
        <Authenticated>
          <HomePage />
        </Authenticated>
      }
    />

    {/* Performance */}
    <Route
      path={performancePath}
      element={
        <Authenticated>
          <PerformancePage />
        </Authenticated>
      }
    />

    {/* Transfer */}
    <Route
      path={transferPath}
      element={
        <Authenticated>
          <TransferPage />
        </Authenticated>
      }
    />

    {/* Documents */}
    <Route
      path={documentsPath}
      element={
        <Authenticated>
          <DocumentsPage />
        </Authenticated>
      }
    />

    {/* Support */}
    <Route
      path={supportPath}
      element={
        <Authenticated>
          <SupportPage />
        </Authenticated>
      }
    />

    {/* Login */}
    <Route
      path={loginPath}
      element={
        <Unauthenticated>
          <LoginPage />
        </Unauthenticated>
      }
    />

    {/* Change Password */}
    <Route
      path={changePasswordPath}
      element={
        <Authenticated>
          <ChangePasswordPage />
        </Authenticated>
      }
    />

    {/* Not Found */}
    <Route path="*" element={<BadRoute />} />
  </Routes>
);
export default AppRoutes;
