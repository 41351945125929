import { atom } from 'recoil';
import { getCurrentUser, UserInfo } from '../services/auth';

export interface UserState {
  currentUser: UserInfo | null;
}

const fetchUserState = async (): Promise<UserState> => ({ currentUser: await getCurrentUser() });

export const userState = atom<UserState>({
  key: 'user',
  default: fetchUserState(),
});
