import { gql } from '@apollo/client';
import { css } from '@emotion/react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';
import { ReportFundSelectionFundDetails } from '../../generated/graphql';
import { useBreakpoints } from '../../style/theme';

export interface ReportFundSelectionProps {
  funds: readonly ReportFundSelectionFundDetails[];
  selected: string;
  onSelect: (fundId: string) => void;
}

const ReportFundSelection = ({ funds, selected, onSelect }: ReportFundSelectionProps) => {
  const theme = useTheme();
  const { mediumDown } = useBreakpoints();

  if (funds.length <= 1) {
    return null;
  }

  const handleChange = (value) => {
    if (!value) {
      return;
    }

    onSelect(value);
  };

  if (mediumDown) {
    return (
      <FormControl size="small" margin="dense" sx={{ minWidth: theme.spacing(15), maxWidth: theme.spacing(15) }}>
        <Select value={selected} onChange={(event) => handleChange(event.target.value)}>
          {funds.map((fund) => (
            <MenuItem key={fund.id} value={fund.id} title={fund.legalName}>
              {fund.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <ToggleButtonGroup size="small" value={selected} onChange={(event, value) => handleChange(value)} exclusive>
      {funds.map((fund) => (
        <ToggleButton
          css={css`
            white-space: nowrap;
          `}
          key={fund.id}
          value={fund.id}
          title={fund.legalName}
        >
          {fund.name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
export default ReportFundSelection;

export const reportFundSelectionFundDetailsFragment = gql`
  fragment ReportFundSelectionFundDetails on Fund {
    id
    name
    legalName
  }
`;
